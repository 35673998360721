<script setup lang="ts">
import { computed, nextTick, PropType, ref } from 'vue';
import axios from 'axios';
import ModalContainer from '@/components/modal/modal/index.vue';
import { Widget, WIDGET_COMPONENT_NAME, WIDGET_MODE, WIDGET_SIZE } from '@/types';
import Cascader from '@/components/widgets/calculator/components/house-loan/cascader.vue';
import * as dayjs from 'dayjs';
import { WEATHER_CITY_LIST } from '@/configs/component';
import AssetHelper from '@/utils/asset-helper';
import { Weather } from '@/types/pref';
import lcLog from '@/lib/lclog';
const WEATHER_ICON_STORAGE_KEY = 'PC_NEW_TAB_WEATHER_ICONS';
const props = defineProps({
  showName: {
    type: Boolean,
    default: true,
  },
  mode: {
    type: String,
    default: WIDGET_MODE.common,
    required: false,
  },
  size: {
    type: String,
    default: WIDGET_SIZE['2x2'],
    required: false,
  },
  widget: {
    type: Object as PropType<Widget>,
    default: () => ({
      size: WIDGET_SIZE['2x2'],
    }),
  },
});
const isPreviewMode = computed(() => props.mode === WIDGET_MODE.preview);
const showModal = ref(false);
const hourListEle = ref();
const moveX = ref(0);
const weatherIconList = ref([]);
const cityOptions = ref([]);
const cascader = ref();
const close = () => {
  showModal.value = false;
};
const handleClick = () => {
  if (isPreviewMode.value) return;
  showModal.value = true;
};

const weekData = ref([]); // 一周天气
const today = ref(); // 今日
const todyHourData = ref([]); // 今日按时间天气
const curCity = ref('');
const curCityCode = ref('');
const curTemperature = ref();
const curWeather = ref('');

const todayData = computed(() => weekData.value.find(data => data.date === today.value) || null);
const showCascader = computed(() => cityOptions.value.length > 0);

function getCurHour() {
  const now = new Date();
  const currentHour = now.getHours();
  const currentTime = currentHour < 10 ? `0${currentHour}时` : `${currentHour}时`;
  return currentTime;
};
const initWeatherData = async () => {
  const weather = AssetHelper.getWeatherData();
  if (weather?.locationCity && weather?.weather) {
    today.value = weather.weather.date;
    weekData.value = weather.weekWeather;
    curCity.value = weather.locationCity.city;
    curCityCode.value = weather.locationCity.cityCode;
    curTemperature.value = weather.weather.temperature;
  }
  getTodyWeather(curCity.value);
};
initWeatherData();

const formatWeather = (data: any) => {
  try {
    const weatherData = data.doc[0].item[0].display[0].subitem[0].subdisplay[0];
    const weekWeatherList = weatherData.day.map(weather => ({
      date: weather.date[0].$,
      weather: weather.daydescription[0].$,
      high: weather.high[0].$,
      low: weather.low[0].$,
      week: weather.week[0].$,
    }));
    const todyWeatherList = weatherData.hour.map((weather) => {
      const time = weather.time[0].$;
      const temperature = weather.temperature[0].$;
      if (time === getCurHour()) {
        curTemperature.value = temperature;
        curWeather.value = weather.state[0].$;
      }
      return {
        time,
        temperature,
        weather: weather.state[0].$,
      };
    });
    weekData.value = weekWeatherList;
    todyHourData.value = todyWeatherList;
    today.value = weatherData.suggtoday[0].$;
    AssetHelper.setWeatherData({
      component: WIDGET_COMPONENT_NAME.weather,
      locationCity: {
        city: curCity.value,
        cityCode: curCityCode.value,
      },
      weather: {
        ...weekData.value.find(data => data.date === today.value) || {},
        temperature: curTemperature.value,
        expire: dayjs().add(1, 'day')
          .startOf('day')
          .valueOf(),
      },
      weekWeather: weekWeatherList,
    } as Weather);
  } catch (error) {
    lcLog.error('formatWeather', error);
  }
};

const getIconByWeather = (name: string) => weatherIconList.value.find(icon => icon.name === name) || {};

const getWeatherIcon = async () => {
  try {
    const weatherIconStr = window.localStorage.getItem(WEATHER_ICON_STORAGE_KEY);
    if (weatherIconStr) {
      const weatherIcons = JSON.parse(weatherIconStr);
      if (Array.isArray(weatherIcons)) {
        weatherIconList.value = weatherIcons;
      }
    } else {
      const url =  `${window.__API_URL__}/new_api/getWeatherIcon`;
      const res = await axios.get(url);
      if (res.status === 200 && res.data && res.data.data.length > 0) {
        weatherIconList.value = res.data.data;
        window.localStorage.setItem(WEATHER_ICON_STORAGE_KEY, JSON.stringify(res.data.data));
      }
    }
  } catch (error) {
    console.log('%c Line:127 🥐 error', 'color:#465975', error);
  }
};

const getLocationCity = async () => {
  const rsp = await axios.post('https://daohang.browser.qq.com/getLocalCity.api');
  const data = rsp.data || {};
  let city = '北京市';
  if (rsp.status === 200) {
    const { cityName, localCity: code } = data;
    if (cityName) {
      city = `${cityName}市`;
    }
    curCity.value = city;
    curCityCode.value = code;
  }
  return city;
};

const getWeatherReq = async (city: string) => {
  const url = `https://vr.m.sogou.com/tworeq?ie=utf8&reqClassids=70269805;70269806;70269810;70269811;70269820;70269821;70269823;70269822&queryFrom=wap&vrForQc=false&dataPlatformSource=weather_card&retType=xml&queryString=${city}天气&format=json`;
  const res = await axios.get(url);
  if (res.status === 200 && res.data && res.data?.doc?.length > 0) {
    formatWeather(res.data);
  }
};

const getTodyWeather = async (city) => {
  await getWeatherIcon();
  const name = city ? city : await getLocationCity();
  getWeatherReq(name);
};


const computedWeatherBar = computed(() => {
  const copyedWeekData = weekData.value.slice(1, 8);
  const result = copyedWeekData.reduce((acc, curr) => {
    const high = parseInt(curr.high, 10);
    const low = parseInt(curr.low, 10);

    if (high > acc.maxHigh) {
      acc.maxHigh = high;
    }

    if (low < acc.minLow) {
      acc.minLow = low;
    }

    return acc;
  }, { maxHigh: -Infinity, minLow: Infinity });
  return result;
});

const getColumStyle = (week) => {
  const { high, low } = week;
  const result = computedWeatherBar.value;
  const baseHeight = 70;
  const rate = result.maxHigh - result.minLow;
  const unitHeight = baseHeight / rate;
  const columHeight = (high - low) * unitHeight;
  return {
    height: `${columHeight}px`,
    top: `${(result.maxHigh - high) * unitHeight}px`,
  };
};
const hourListStyle = ref({});
const moveLeft = () => {
  const $hourList = hourListEle.value;
  const $parent = $hourList.parentNode;
  const width = $hourList.clientWidth;
  const parentWidth = $parent.clientWidth;
  const distance = width - parentWidth;
  const moveUnit = 180;
  if (distance > 0 && moveX.value > 0) {
    moveX.value -= (moveX.value >= moveUnit ? moveUnit : moveX.value);
    hourListStyle.value = {
      'transition-timing-function': 'ease',
      'transition-property': 'transform',
      'transition-duration': '200ms',
      transform: `translateX(${-1 * moveX.value}px) translateY(0px) translateZ(1px)`,
    };
  };
};
const moveRight = () => {
  const $hourList = hourListEle.value;
  const $parent = $hourList.parentNode;
  const width = $hourList.clientWidth;
  const parentWidth = $parent.clientWidth;
  const distance = width - parentWidth;
  const moveUnit = 180;
  const movedWidth = distance - moveX.value;
  if (distance > 0 && movedWidth > 0) {
    moveX.value += (movedWidth >= moveUnit ? moveUnit : movedWidth);
    hourListStyle.value = {
      'transition-timing-function': 'ease',
      'transition-property': 'transform',
      'transition-duration': '200ms',
      transform: `translateX(${moveX.value * -1}px) translateY(0px) translateZ(1px)`,
    };
  };
};

const onSearch = (e: Event) => {
  const val = e.target.value;
  let cityList = [];
  if (!val.trim()) {
    cityList = [];
    cityOptions.value = cityList;
    return;
  }
  WEATHER_CITY_LIST.forEach((province) => {
    const filterCity = province.children?.filter(city => city.label.includes(val)) || [];
    if (filterCity.length) {
      cityList = cityList.concat(filterCity);
    }
  });
  cityOptions.value = cityList;
  nextTick(() => {
    cascader.value.showList();
  });
};

const onSelect = (data) => {
  getWeatherReq(`${data.label}市`);
  curCity.value = `${data.label}市`;
  curCityCode.value = '';
};

const formatTime = (time) => {
  if (time === '00时') {
    return '0时';
  }
  return time;
};

</script>
<template>
  <div
    class="weather-widget h-full w-full"
  >
    <div
      v-if="props?.widget?.size === WIDGET_SIZE['2x2']"
      class="h-full w-full contents"
      @click="handleClick"
    >
      <div class="main-content">
        <div
          class="weather-top"
        >
          <i class="icon-position" />
          <span class="city">{{ curCity }}</span>
        </div>
        <div
          v-if="todayData"
          class="weather-icon"
          :style="{
            backgroundImage: `url(${getIconByWeather(todayData.weather).medium_icon})`
          }"
        />
        <div
          v-if="todayData"
          class="weather-footer"
        >
          <div class="temperature-module">
            <span class="temperature-light">最高{{ todayData.high }}&deg;</span>
            <span class="temperature-light">最低{{ todayData.low }}&deg;</span>
          </div>
          <div class="temperature-bold">
            {{ curTemperature }}&deg;
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="h-full w-full contents weather-component-medium"
      @click="handleClick"
    >
      <div class="main-content">
        <div
          class="weather-top"
        >
          <i class="icon-position" />
          <span class="city">{{ curCity }}</span>
        </div>
        <div
          v-if="todayData"
          class="weather-icon"
          :style="{
            backgroundImage: `url(${getIconByWeather(todayData.weather).medium_icon})`
          }"
        />
        <div
          v-if="todayData"
          class="weather-middle"
        >
          <div class="temperature-bold">
            {{ curTemperature }}&deg;
          </div>
          <div class="weather-middle-right">
            <div class="flex-row">
              <span class="label">最高 {{ todayData.high }}&deg;</span>
              <span class="label">最低 {{ todayData.low }}&deg;</span>
              <span class="weather-name">{{ todayData.weather }}</span>
            </div>
          </div>
        </div>
        <div class="weather-medium-footer">
          <div
            v-for="(week, index) in weekData.slice(2, 8)"
            :key="week.date"
            class="week-weather-item"
          >
            <span class="label">{{ week.week }}</span>
            <img
              class="middle-icon-weather"
              :src="getIconByWeather(week.weather).small_icon"
              alt=""
            >
            <span class="weather" />
            <div class="temperature-box">
              <span
                class="temperature-num"
              >{{ week.low }}&deg;</span>
              <span class="temperature-num">{{ week.high }}&deg;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p
      v-if="props.showName"
      class="item-title widget-item-name"
    >
      天气
    </p>
    <ModalContainer
      v-if="showModal"
      class="weather-modal-container"
      :preventContextMenu="true"
      @close="close"
    >
      <div class="weather-modal-content">
        <div class="weather-modal-top">
          <span class="top-component-name">天气</span>
          <div class="weather-top">
            <i class="icon-position" />
            <span class="city">{{ curCity }}</span>
          </div>
          <div class="weather-search-box">
            <input
              placeholder="切换城市"
              class="weather-search-input"
              type="text"
              @input="onSearch"
            >
          </div>
          <div
            v-if="showCascader"
            class="city-cascader"
          >
            <Cascader
              ref="cascader"
              :options="cityOptions"
              @select="onSelect"
            />
          </div>
        </div>
        <div
          class="weather-modal-icon"
          :style="{
            backgroundImage: `url(${getIconByWeather(todayData.weather).large_icon})`
          }"
        />
        <div class="weather-modal-main">
          <div class="weather-modal-today">
            <div class="today-date">
              <span class="label">{{ todayData.date }}</span>
              <span class="label">&nbsp;{{ todayData.week }}</span>
            </div>
            <div class="today-weather">
              <span class="label temperature">{{ curTemperature }}&deg;</span>
              <div class="flex-column flex-end">
                <div class="label-weather-box">
                  <span class="label">{{ todayData.weather }}</span>
                  <img
                    class="label-weather-icon"
                    :src="getIconByWeather(todayData.weather).small_icon"
                    alt=""
                  >
                </div>
                <div class="flex-row">
                  <span class="label">最高{{ todayData.high }}&deg;</span>
                  <span
                    class="label"
                  >&nbsp;最低{{ todayData.low }}&deg;</span>
                </div>
              </div>
            </div>
          </div>
          <div class="weather-modal-hours">
            <a
              class="operator"
              @click="moveLeft"
            >
              <i class="icon-arrow" />
            </a>
            <div
              class="weather-hour-list-scroll"
            >
              <div
                ref="hourListEle"
                :style="hourListStyle"
                class="weather-hour-list"
              >
                <div
                  v-for="(today, index) in todyHourData"
                  :key="index"
                  class="weather-hour-item"
                >
                  <span class="hour-temperature">{{ today.temperature }}&deg;</span>
                  <img
                    class="hour-temperature-icon"
                    :src="getIconByWeather(today.weather).small_icon"
                  >
                  <span class="hour-text">{{ formatTime(today.time) }}</span>
                </div>
              </div>
            </div>
            <a
              class="operator operator-right"
              @click="moveRight"
            >
              <i class="icon-arrow icon-arrow-right" />
            </a>
          </div>
          <div class="weather-modal-week">
            <div
              v-for="(week, index) in weekData.slice(1, 8)"
              :key="week.date"
              :class="{
                'week-weather-item': true,
                active: index === 0,
              }"
            >
              <span class="label">{{ week.week }}</span>
              <span class="date">{{ week.date.slice(5) }}</span>
              <span
                class="weather"
                :style="{
                  backgroundImage: `url(${getIconByWeather(week.weather).small_icon})`
                }"
              />
              <div class="temperature-box">
                <span class="temperature-high label">{{ week.high }}&deg;</span>
                <div
                  class="temperature-column"
                >
                  <div
                    :style="getColumStyle(week)"
                    class="temperature-colum-black"
                  />
                </div>
                <span
                  class="temperature-low"
                  label
                >{{ week.low }}&deg;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.weather-modal-container {
  &.modal-container {
    .modal-body {
      padding: 24px;
      height: 800px;
      .modal-content {
        height: 100%;
        max-height: none !important;
        overflow-y: visible;
      }
    }
  }
}
</style>
