<script setup lang="ts">
import { genUuid } from '@/utils/util';
import { onMounted, defineEmits, onBeforeUnmount, computed, ref } from 'vue';

const props = defineProps({
  preventContextMenu: Boolean,
});

const idRef = ref(genUuid().slice(0, 6));

const bindOptions = computed(() => (props.preventContextMenu ? {
  contextmenu: (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  },
} : {}));

onMounted(() => {
  document.body.appendChild(document.getElementById(idRef.value) as Node);
});

onBeforeUnmount(() => {
  document.body.removeChild(document.getElementById(idRef.value) as Node);
});

const emit = defineEmits(['close']);

const close = () => {
  emit('close');
};
</script>
<template>
  <div
    :id="idRef"
    class="modal-container"
    dt-keep-report="true"
    v-on="bindOptions"
  >
    <div
      class="mask"
      @click="close"
    />
    <div
      class="modal-body modal-box-shadow"
    >
      <div
        class="icon-close"
        dt-keep-report="true"
        dt-imp-ignore="true"
        dt-eid="close_add_panel"
        @click="close"
      />
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
