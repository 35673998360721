import { WIDGET_COMPONENT_NAME } from '.';

export enum ERspCode {
  SUCC = '0',
  RR_TOKEN_EXPIRED = '3000', // token过期，尝试重刷
  ERR_TOKEN_VERIFY = '3001', // 登陆态校验失败
  ERR_REDIS_SET = '3002', // redis写入错误
  ERR_REDIS_GET = '3003', // redis数据读取错误
  ERR_INTERNAL = '4000', // 系统内部错误
  ERR_VERSION_EXPIRED = '2001', // 版本号不是最新，需要拉取最新数据
}

export interface Account {
  account_type: number;
  account_id: string;          // 微信物联登录体系账号openId, qq
  appid: string;              // appid	，互联：100446242 微信：wx64f9cf5b17af074d
}

export interface Token {
  token_type: number;          // IdcenterTokenType
  token: string;      // 票据
};

export interface BaseUserInfo {
  device_id: string;  // 未登录时的设备id
  qbid: string;  // 登陆id，可空 与登录态绑定
  account: Account; // 可空 与登录态绑定
  token: Token; // 可空 与登录态绑定
};

export interface GetTabsReq {
  base_user_info: BaseUserInfo;
  with_first_tab_info: BaseUserInfo;
}

export interface TabInfos {
  tab_infos: TabInfo[];
};

export interface TabInfo {
  id?: string; // 如果是首次上传的tab，不需要填，后台会生成唯一id
  name: string;
  icon: string; // 终端埋下的icon图标对应tag
}

export interface GetTabsRsp {
  tabs: TabInfo[];
  first_quick_links: QuickLink[];
  is_first_come_in: boolean;
  version: number;
}

export interface UpdateTabsReq {
  base_user_info: BaseUserInfo;
  tabs: TabInfo[];
}

export interface UpdateTabsRsp {
  version: number;
}

export interface QuickLinkSize {
  length: number;
  height: number;
}

export interface QuickLink {
  id?: string; // 如果不是用户自定义的，必填。取数据库id
  title: string;
  url: string;
  img: string;
  icon_background?: string;
  quick_link_type?: WIDGET_COMPONENT_NAME;
  size?: QuickLinkSize;
  quick_link_data?: string;
}

export interface GetTabQuickLinksReq {
  base_user_info: BaseUserInfo;
  tab_id: string;
}

export interface GetTabQuickLinksRsp {
  quick_links: QuickLink[];
  version: number;
}

export interface UpdateQuickLinksRsp {
  version: number;
}

export interface UpdateQuickLinksDataRsp {
  version: number;
}

export interface UpdateQuickLinksReq {
  base_user_info: BaseUserInfo;
  tab_id: string;
  quick_links: QuickLink[];
  version: number;
}

export interface QuickLinkData {
  id: string; // 同快链id
  data: string; // 序列化的快链数据，后台不理解
}

export interface GetQuickLinksDataReq {
  base_user_info: BaseUserInfo;
  quick_link_id: string; // 快链id，多个标签下相同
}

export interface GetQuickLinksDataRsp {
  quick_link_data: string; // 序列化的快链数据
}

export interface UpdateQuickLinksDataReq {
  base_user_info: BaseUserInfo;
  quick_link_data: QuickLinkData;
  version: number;
}

export interface MigrationTabData {
  tab: TabInfo;
  quick_links: QuickLink[];
}

export interface MigrationUserDataReq {
  base_user_info: BaseUserInfo;
  migration_tabs: MigrationTabData[];
}

export interface MigrationUserDataRsp {
  version: number;
}

export interface RpcResponse {
  status: number;
  'trpc-ret'?: number;
  'trpc-func-ret'?: ERspCode;
  'trpc-error-msg'?: string;
}
