import global, { GROUP_ICON_LIST } from '@/configs/global';
import store, { getLocationReq } from './store';
import util from './util';
import lcLog, { doReport } from '@/lib/lclog';

const USER_DATA_KEY = 'NewTab.FavoriteTab';
const DEFAULT_USER_DATA = {
  groups: [],
  widgets: [],
  list: [],
  isFirstComeIn: true,
};

let userData: any = null;

/**
 * 读取用户数据
 */
async function getUserData() {
  doReport({
    action: 'getUserData start',
    data: {
      userData,
    },
  });
  try {
    const value = await util.getPrefs(USER_DATA_KEY);
    if (value) {
      userData = JSON.parse(value);
      if (!userData?.list?.length) {
        userData.list = [];
      }
    } else {
      userData = {
        ...global.GUEST_DATA,
        ...DEFAULT_USER_DATA,
      };
    }
    if (!isQbClient()) {
      const localGroup = window.localStorage.getItem('groups');
      const localWidget = window.localStorage.getItem('widgets');
      const todoList = window.localStorage.getItem('todoList');
      const weatherData = window.localStorage.getItem('weather');
      let isFirstComeIn: string | boolean | null = window.localStorage.getItem('isFirstComeIn');
      if (!localGroup) {
        userData.groups = [];
      } else {
        userData.groups = JSON.parse(localGroup) || [];
      }
      if (!localWidget) {
        userData.widgets = [];
      } else {
        userData.widgets = JSON.parse(localWidget) || [];
      }
      if (isFirstComeIn === null) {
        isFirstComeIn = true;
      } else {
        isFirstComeIn = false;
      }
      if (!weatherData) {
        userData.weather = {};
      } else {
        userData.weather = JSON.parse(weatherData) || {};
      }
      userData.isFirstComeIn = isFirstComeIn;
      userData.todoList = JSON.parse(todoList) || [];
    }
    const city = await getLocationReq() || '';

    // 剔除无效数据
    const list =  userData?.list?.length ? userData.list : [];
    if (city.includes('北京')) {
      list.filter((item) => {
        if (util.isSogou() && item.title === '百度') {
          item.url = 'https://www.baidu.com/?tn=87048150_dg&ch=2';
        }
        return item?.url;
      });
    }
    userData.list = list;
    doReport({
      action: 'getUserData end',
      data: {
        userData,
      },
    });
    return userData;
  } catch (error) {
    console.log('%c Line:89 🍆 error', 'color:#7f2b82', error);
    doReport({
      action: 'getUserData error',
      data: {
        error: JSON.stringify(error),
      },
    });
  }
}

const isQbClient = () => util.isQB() || util.isSogou();

async function setUserData(key: string, data: any = []) {
  try {
    doReport({
      action: 'setUserData start',
      data: {
        key,
        data,
        userData,
      },
    });
    userData[key] = data;
    if (isQbClient()) {
      await util.setPrefs(USER_DATA_KEY, JSON.stringify(userData));
    } else {
      window.localStorage.setItem(key, JSON.stringify(data));
    }

    doReport({
      action: 'setUserData end',
      data: {
        key,
        data,
        userData,
      },
    });
  } catch (error) {
    doReport({
      action: 'setUserData error',
      data: {
        key,
        error: JSON.stringify(error),
      },
    });
  }
}

const DEFAULT_GROUPS_ITEM = {
  name: '默认分组',
  icon: GROUP_ICON_LIST[0].name,
};
const DEFAULT_GROUPS = [
  {
    name: '生活娱乐',
    icon: GROUP_ICON_LIST[4].name,
  },
  {
    name: '工作学习',
    icon: GROUP_ICON_LIST[1].name,
  },
  {
    name: '购物出行',
    icon: GROUP_ICON_LIST[5].name,
  },
];

export const initLocalGroupsAndSites = async () => {
  try {
    console.time('init');
    let defaultGroups = [];
    let sitesList = [];
    const {
      list: defaultSites = [],
      widgets: sites = [],
      groups = [],
      isFirstComeIn,
    } = await getUserData();
    const hasSites = defaultSites && defaultSites.length > 0;
    if (isFirstComeIn) {
      if (!hasSites) {
        defaultGroups = DEFAULT_GROUPS;
      } else {
        defaultGroups = [
          DEFAULT_GROUPS_ITEM,
          ...DEFAULT_GROUPS,
        ];
      }
    }
    if (!sites.length) {
      if (isFirstComeIn) {
        lcLog.info('[initLocalGroupsAndSites] restore', defaultSites);
        const DEFAULT_SITES_LIST = [global.DEFAULT_SITES_TWO, global.DEFAULT_SITES_ONE, global.DEFAULT_SITES_THREE];
        const sites = hasSites ? [defaultSites, ...DEFAULT_SITES_LIST] : DEFAULT_SITES_LIST;
        sitesList = sites;
      }
    } else {
      sitesList = sites;
    }
    const wordLearnGroupIdx = defaultGroups.findIndex(group => group.name === '工作学习');
    const isEqual = wordLearnGroupIdx >= 0
    && JSON.stringify(sitesList[wordLearnGroupIdx]) === JSON.stringify(global.DEFAULT_SITES_ONE);
    if (isEqual) {
      const mailIdx = sitesList[wordLearnGroupIdx].findIndex(site => site?.title === '163邮箱' && site?.url === 'http://mail.163.com/');
      if (mailIdx >= 0) {
        sitesList[wordLearnGroupIdx][mailIdx] = {
          img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_2sEJh_s1QSd.png',
          title: '腾讯会议',
          url: 'https://meeting.tencent.com/activities/index.html?fromSource=sem2_gwzcw.3894088.3894088.3894088',
        };
      }
    }
    userData = {
      ...userData,
      groups: groups.length ? groups : defaultGroups,
      widgets: sitesList,
    };
    doReport({
      action: 'initLocalGroupsAndSites end',
      data: {
        userData,
      },
    });
    console.timeEnd('init');
    return userData;
  } catch (error) {
    lcLog.error('[initLocalGroupsAndSites]', error);
    console.log('error', error);
  }
};

const getDefaultGroupName = (retry: number, groups: { name: string}[]): string => {
  if (!retry && !groups.find(v => v.name === '默认分组')) return '默认分组';
  if (!retry || groups.find(v => v.name === `默认分组（${retry}）`)) return getDefaultGroupName(retry ? retry + 1 : 1, groups);
  return `默认分组（${retry}）`;
};

export const restoreHistoryGroupsAndSites = async () => {
  try {
    let defaultGroups = [];
    let sitesList = [];
    const defaultSites = await store.getListData();
    const sites = await getWidgetByLS();
    const groups = await getGroupByLS();

    if (defaultSites.length <= 0) return {
      groups,
      widgets: sites,
    };

    const restoreGroup = {
      name: getDefaultGroupName(0, groups),
      icon: GROUP_ICON_LIST[0].name,
    };
    defaultGroups = [restoreGroup, ...groups];
    sitesList = [defaultSites, ...sites];
    return {
      groups: defaultGroups,
      widgets: sitesList,
    };
  } catch (error) {
    lcLog.error('[restoreHistoryGroupsAndSites]', error);
    console.log('error', error);
  }
};

export const getGroupByLS = async () => {
  try {
    let groupList = [];
    if (isQbClient()) {
      const userData = await getUserData();
      groupList = userData.groups;
    } else {
      const localGroup = window.localStorage.getItem('groups') || '';
      groupList = JSON.parse(localGroup);
    }
    return groupList || [];
  } catch (e) {
    return [];
  }
};
export const setGroup2LS = (groups: any[]) => {
  try {
    setUserData('groups', groups);
  } catch (e) {
    return [];
  }
};

export const getWidgetByLS = async () => {
  try {
    let widgetList = [];
    if (isQbClient()) {
      const userData = await getUserData();
      widgetList = userData.widgets;
    } else {
      const localWidget = window.localStorage.getItem('widgets') || '';
      widgetList = JSON.parse(localWidget);
    }
    const widgetList2 = widgetList || [];
    const data = widgetList2.map(widgets => widgets.filter(item => item !== null));
    return data || [];
  } catch (e) {
    return [];
  }
};

export const setWidget2LS = (widgets: any[]) => {
  try {
    if (isQbClient()) {
      setTimeout(() => {
        setUserData('widgets', widgets);
      }, 300);
    } else {
      setUserData('widgets', widgets);
    }
  } catch (e) {
    return [];
  }
};


export const getFirstComeIn = () => {
  try {
    let isFirstComeIn = false;
    if (isQbClient()) {
      const flag = userData.isFirstComeIn;
      isFirstComeIn = typeof flag === 'undefined' ? true : flag;
    } else {
      const localIsFirstComeIn = window.localStorage.getItem('isFirstComeIn') || '';
      isFirstComeIn = JSON.parse(localIsFirstComeIn);
    }
    return isFirstComeIn;
  } catch (e) {
    return false;
  }
};

export const setFirstComeIn = (isFirstComeIn: boolean) => {
  try {
    if (isQbClient()) {
      setUserData('isFirstComeIn', isFirstComeIn);
    } else {
      window.localStorage.setItem('isFirstComeIn', JSON.stringify(isFirstComeIn));
    }
  } catch (e) {
    return false;
  }
};

export const getAllUserData = () => userData;
export const setUserDataPlus = (key: string, value: any) => {
  try {
    if (isQbClient()) {
      setUserData(key, value);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (e) {
    return false;
  }
};
