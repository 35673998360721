import QbAPI from '@/utils/qb-api';
import logger, { doReport } from './lclog';

const setQbId = async () => {
  const { qbid, isLogin } = (await QbAPI.getLoginInfo()) || {};
  logger.setOptions({
    ext3: isLogin ? qbid : '',
  });
};

setQbId();

export function onLoginStatusChanged(callback: () => void) {
  window.chrome?.account?.onLoginStatusChanged?.addListener?.((res) => {
    setQbId();
    callback?.();
    doReport({
      action: 'loginStatusChanged',
      data: {
        res,
      },
    });
  });
}
