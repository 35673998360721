export default {
  CONFIRM: '确定',
  TODAY: '今天',
  WEEK: ['日', '一', '二', '三', '四', '五', '六'],
  MONTH: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  CN_MONTH: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  DEFAULT_DATE_FORMAT: 'YY年MM月DD日',
  DEFAULT_YEAR_MONTH_FORMAT: 'YY年MM月',
  DEFAULT_TIME_FORMAT: 'hh:mm',
  DEFAULT_SIMPLE_YEAR_MONTH_FORMAT: 'YY.MM',
};
