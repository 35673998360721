<script setup lang="ts">
import { MonthPayItem } from '@/types';
import { PropType } from 'vue';

const props = defineProps({
  repaymentTypeText: String,
  repaymentTotal: Number,
  interestTotal: Number,
  mouthData: {
    type: Array as PropType<MonthPayItem[]>,
    default: () => [],
  },
});

const getTotal = mouth => (mouth.yuebenjin + mouth.yuelixi).toFixed(2);
const emits = defineEmits(['back']);
const goBack = () => {
  emits('back');
};
</script>
<template>
  <div class="house-loan-result-panel">
    <button
      class="btn-back"
      @click="goBack"
    />
    <div class="panel-body">
      <div class="panel-row">
        <div class="panel-cell panel-cell-label">
          还款方式
        </div>
        <div class="panel-cell panel-cell-label">
          还款总额
        </div>
        <div class="panel-cell panel-cell-label">
          利息总额
        </div>
      </div>
      <div class="panel-row mt24">
        <div class="panel-cell">
          {{ props.repaymentTypeText }}
        </div>
        <div class="panel-cell">
          {{ props.repaymentTotal.toFixed(2) }}
        </div>
        <div class="panel-cell">
          {{ props.interestTotal.toFixed(2) }}
        </div>
      </div>
    </div>
    <div class="result-tip">
      <span>单位：元</span>
    </div>
    <div class="panel-table">
      <table class="table">
        <thead>
          <tr>
            <th class="panel-table-cell">
              期数
            </th>
            <th class="panel-table-cell">
              月供
            </th>
            <th class="panel-table-cell">
              月供本金
            </th>
            <th class="panel-table-cell">
              月供利息
            </th>
            <th class="panel-table-cell">
              剩余本息
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr
            v-for="(mouth, index) in props.mouthData"
            :key="index"
            class="panel-table-row"
          >
            <td class="panel-table-cell">
              {{ index + 1 }}
            </td>
            <td class="panel-table-cell">
              {{ getTotal(mouth) }}
            </td>
            <td class="panel-table-cell">
              {{ mouth.yuebenjin.toFixed(2) }}
            </td>
            <td class="panel-table-cell">
              {{ mouth.yuelixi.toFixed(2) }}
            </td>
            <td class="panel-table-cell">
              {{ mouth.leftFund.toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="footer-tip">
      --结果仅供参考--
    </p>
  </div>
</template>
<style lang="less" scope>
@import './loan-result.less';
</style>
