import lcLog, { doReport } from '@/lib/lclog';
import { v4 as uuidv4 } from 'uuid';

function getPrefs(key: string) {
  return new Promise((resolve) => {
    try {
      chrome.prefs.getStringPref(key, (res) => {
        doReport({
          action: 'chrome.prefs.getStringPref.start',
          data: res,
          key,
        });
        if (res && res !== 'null') {
          const tmp = JSON.parse(res);
          tmp?.list?.forEach((item) => {
            if (item?.url && item.url === 'http://video.browser.qq.com/live/beauty/?ADTAG=newtabweb') {
              item.url = 'https://now.qq.com/pcweb/index.html?from=98002&ADTAG=gdh-kz';
            }
            if (item?.url && item.url.indexOf('union.click.jd.com') > -1) {
              item.url = 'https://union-click.jd.com/jdc?e=0&p=AyIPZRprFDJWWA1FBCVbV0IUEEULRFRBSkAOClBMW2VrH0oCEQQWHDgSGG4AD3oAaEZwQyhNVxkyEQ9dH1wSARcGZRhTHQYUBlQYUiUyEgZlUDUUMhcHVx5TFgISAWUbXh0AEwZVE1gQCxEPZRxrTld8AAAbUhADFAAGG15HAyI3ZSs%3D&t=W1dCFBBFC0RUQUpADgpQTFs%3D';
            }
          });
          doReport({
            action: 'chrome.prefs.getStringPref.success',
            data: tmp,
            key,
          });
          resolve(JSON.stringify(tmp));
        } else {
          doReport({
            action: 'chrome.prefs.getStringPref.empty',
            data: res,
            key,
          });
          resolve(res);
        }
      });
    } catch (error) {
      doReport({
        action: 'chrome.prefs.getStringPref.error',
        data: JSON.stringify(error),
        key,
      });
      resolve();
    }
  });
}

/**
 * set to pref
 * @param {String} key
 * @param {String} value
 */
async function setPrefs(key, value) {
  try {
    await chrome.prefs.setStringPref(key, value);
  } catch (error) {
  }
}

function onPrefsChanged(callback) {
  try {
    chrome.prefs.onChanged.addListener(callback);
  } catch (error) {

  }
}

/**
 * 设置当前壁纸为桌面
 * @param {[type]}   value    [description]
 */
function setScreen(image) {
  return new Promise((resolve, reject) => {
    try {
      chrome.qbSkin.setScreenBg(image, (res) => {
        resolve(res.successed);
      });
    } catch (error) {
    }
  });
}

/**
 * 设置当前壁纸为桌面
 */
async function setScreenBg(image) {
  return (await setScreen(image || '')) || false;
}

/**
 * encodeHTML
 */
function encodeHTML(str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/`/g, '&#x60;')
    .replace(/\//g, '&#x2F;');
}

/**
 * decodeHTML
 */
function decodeHTML(str) {
  return str
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, '\'')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&#x60;/g, '`')
    .replace(/&#x2F;/g, '/');
}

/**
 * 获取字符串的第一个汉字或字母
 * @param {String} str
 */
function getFirstWord(str) {
  let res = '';
  const tmp = (str || '').match(/[\u4E00-\u9FA5\uF900-\uFA2D]|[a-zA-Z0-9]/);
  res = tmp?.length ? tmp[0] : '';
  return res;
}

/**
 * 通过url获取icon
 * @param  {Array} urlArr url数组
 * @return {Object}
 */
async function getIconAndTitleByUrl(urlArr) {
  const data = {};
  try {
    const response = await window.fetch(`https://cm.browser.qq.com/newtab/kw_query_batch_new?size=100&kw=${window.encodeURIComponent(JSON.stringify(urlArr))}&t=${Date.now()}`);
    const res = await response.json();

    if (res?.data) {
      for (const url in res.data) {
        if (isUrl(url) && res.data[url]?.data?.[0] && isUrl(res.data[url].data[0].thumbnail)) {
          data[url] = res.data[url].data[0];
        }
      }
    }
  } catch (err) {
    console.error('getIconAndTitleByUrl', err);
  }
  return data;
}

/**
 * get color by url
 * @urlArr {Array} urlArr icon url array
 */
function getIconColorByUrl(urlArr) {
  if (Array.isArray(urlArr) && urlArr.length) {
    const res = [];
    urlArr.forEach((url) => {
      const { origin } = parseUrl(url);
      if (isUrl(origin)) {
        res.push(`${origin}/favicon.ico`);
      }
    });

    if (res.length) {
      chrome?.qbIcon?.getIconColor?.(res);
    }
  }
}

/**
 * onGetIconColorFinished
 * @param  {Function} callback
 * @return {Object} icon color object
 */
function onGetIconColorFinished(callback) {
  try {
    chrome.qbIcon.onGetIconColorFinished.addListener((res) => {
      if (res.originColor) {
        res.rgba = `rgba(${res.originColor.red},${res.originColor.green},${res.originColor.blue},${parseInt(res.originColor.alpha / 2.55)})`;
        callback(res);
      }
    });
  } catch (error) {

  }
}

/**
 * isDomain
 * https://www.npmjs.com/package/is-domain
 * @param {String} str
 */
function isDomain(str) {
  return /^[a-zA-Z0-9_-]+\.[.a-zA-Z0-9_-]{2,}$/.test(str);
}

/**
 * Loosely validate a URL `string`.
 * https://www.npmjs.com/package/is-url
 * @param {String} string
 */
function isUrl(str) {
  if (typeof str !== 'string') {
    return false;
  }

  const match = str.match(/^(?:\w+:)?\/\/(\S+)$/);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (/^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/.test(everythingAfterProtocol)
    || /^[^\s\.]+\.\S{2,}$/.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

/**
 * parse url
 * @param {String} url
 */
function parseUrl(url) {
  try {
    return new window.URL(url);
  } catch (err) {
    return {};
  }
}

export function genQueryString(obj = {}) {
  return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

export function getGuid() {
  try {
    return window.external.getGuid().replace(/-/g, '');
  } catch (e) {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  }
}

export const getTGuid = () => {
  let guid = '';
  try {
    // @ts-ignore
    guid = window.external.getFavSyncGuid();
  } catch (e) { }
  return guid;
};

// 判断是否是qb
export const isQB = () => {
  try {
    const ua = (window.qb_external?.getQUA()) || '';
    return /PR=PC/.test(ua);
  } catch (error) {

  }
};

// 判断是否是搜狗融合版本
export const isSogou = () => {
  const ua = (window.qb_external?.getQUA()) || '';
  return /PR=SE/.test(ua);
};

// 判断是否是搜狗融合版本
export const getQUA = () => (window.qb_external?.getQUA()) || '';

export const getAssetsFile = (url: string) => new URL(`../assets/${url}`, import.meta.url).href;

type Procedure = (...args: any[]) => void;

export const debounce = <F extends Procedure>(
  func: F,
  wait: number,
  immediate = false,
): ((this: ThisParameterType<F>, ...args: Parameters<F>) => ReturnType<F>) => {
  let timeout: ReturnType<typeof setTimeout> | null;
  let args: Parameters<F> | null;
  let context: ThisParameterType<F> | null;
  let timestamp: number;
  let result: ReturnType<F> | undefined;

  const later = function (this: ThisParameterType<F>) {
    const last = new Date().getTime() - timestamp;
    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context!, args!);
        context = args = null;
      }
    }
  };

  return function (this: ThisParameterType<F>) {
    context = this;
    args = arguments as unknown as Parameters<F>;
    timestamp = new Date().getTime();
    const callNow = immediate && !timeout;
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = func.apply(context!, args);
      context = args = null;
    }
    return result as ReturnType<F>;
  };
};

export const throttle = (func: Function, delay: number) => {
  let timerId: any = null;

  return function (...args: any[]) {
    if (!timerId) {
      timerId = setTimeout(() => {
        func(...args);
        timerId = null;
      }, delay);
    }
  };
};

export const isMac = () => /macintosh|mac os x/i.test(navigator.userAgent);

export const canScale = () => window.devicePixelRatio !== 0;

export const hasUrlParams = (key: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.has(key) || false;
};

export const getCookie = (name: string) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const setCookie = (name: string, value: any, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const checkTestEnv = () => {
  const { host } = window.location;
  if (host === 'feeds.qq.com') {
    return false;
  }
  return true;
};

export const getQimei36 = () => {
  try {
    return window.qb_external.getQ36() || '';
  } catch (error) {
    return '';
  }
};


export const genUuid = () => uuidv4();

export const safeJSONParse = (jsonStr: string, defaultValue: any = undefined) => {
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    return defaultValue;
  }
};

export const safeJsonStringify = (jsonObj: Object, defaultValue = '') => {
  try {
    return JSON.stringify(jsonObj);
  } catch (e) {
    return defaultValue;
  }
};


export const standardErrorHandler = {
  onError: (e: Error) => {
    console.log(e);
    lcLog.error(e);
  },
};

/**
 * 创建一个uuid
 * @returns uuid
 */
export const createUniqueId = () => Math.random().toString(36)
  .slice(2, 7);

/**
  * Promise.allSettled polyfill
  * @param {*} tasks 任务数组
  * @returns 新的promise
*/
export const allSettledForPromise = <T>(tasks: Promise<T>[]) => {
  if (Promise.allSettled) {
    return Promise.allSettled(tasks);
  }

  return Promise.all(tasks.map(task => task
    .then(value => ({
      status: 'fulfilled',
      value,
    }))
    .catch(reason => ({
      status: 'rejected',
      reason,
    })))) as Promise<PromiseSettledResult<T>[]>;
};

export function objectToBase64(obj: Object) {
  if (!obj) {
    return '';
  }

  const jsonString = safeJsonStringify(obj);
  const base64String = btoa(unescape(encodeURIComponent(jsonString)));

  return base64String;
};

export function base64ToObject(base64String: string) {
  if (!base64String) {
    return null;
  }

  const jsonString = decodeURIComponent(escape(atob(base64String)));

  return safeJSONParse(jsonString);
};

/**
 * 侧边栏是否打开
 * @param url 对应侧边栏内容url
 * @returns boolean
 */
export const isNativeSidebarOpened = (url: string) => new Promise((resolve) => {
  window.chrome.qbAioWindow?.getAioActiveTabId?.((res: any) => {
    const { id } = res || {};
    resolve(url === id);
  });
});

/**
 * 是否支持侧边栏
 * @returns boolean
 */
export const isSupportNativeSidebar = () => !!window.chrome.qbAioWindow?.openUrlInTab;

/**
 * https://iwiki.woa.com/p/4010965237
 * @param url 侧边栏内容链接
 * @param isForceLoad 是否强制刷新
 * @returns Promise
 */
export const openNativeSidebar = (url: string, isForceLoad = false) => new Promise((resolve) => {
  window.chrome.qbAioWindow?.openUrlInTab?.(url, url, false, isForceLoad, (res: any) => {
    resolve(res);
  });
});

/**
 * 基于 fetch API 的异步请求封装
 * 如果 HTTP 请求状态不是 'ok'，则抛出错误
 * @param input 请求的 URL
 * @param init 可选的请求配置
 * @returns 返回的数据
 */
export const fetcher = async <T>(input: RequestInfo, init?: RequestInit): Promise<T> => {
  const res = await fetch(input, init);
  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const result = res.json() as Promise<{ data: T }>;
  return (await result).data;
};

export const estimateStringWidth = (text: string, fontSize = 14) => {
  const chineseCharWidth = fontSize; // 中文字符宽度
  const otherCharWidth = fontSize / 2 + 2; // 数字和大写英文字符宽度
  let width = 0;

  for (const char of text) {
    // 中文字符
    if (/[\u4e00-\u9fa5]/.test(char)) {
      width += chineseCharWidth;
    } else {
      width += otherCharWidth;
    }
  }

  return width;
};

export default {
  getPrefs,
  setPrefs,
  onPrefsChanged,
  getFirstWord,
  encodeHTML,
  decodeHTML,
  getIconAndTitleByUrl,
  onGetIconColorFinished,
  getIconColorByUrl,
  isDomain,
  isUrl,
  parseUrl,
  genQueryString,
  getGuid,
  getTGuid,
  isQB,
  isSogou,
  getQUA,
  setScreenBg,
  isMac,
  getCookie,
  setCookie,
  checkTestEnv,
};
