export enum CardType {
  single_card = 1,
  carousel_card,
  simple_card,
  row_cell_card,
}

export enum MediaType {
  image = 1,
  video = 2,
}

// export interface Group {

// }

export enum Operation {
  EDIT = 'edit',
  ADD = 'add',
}

export interface Position {
  left: string;
  top: string;
  zIndex?: number;
}

export enum WIDGET_MODE {
  common = 'common',
  preview = 'preview',
}

export interface Widget {
  id: string;
  title: string;
  type: WIDGET_TYPE;
  size: WIDGET_SIZE;
  component: WIDGET_COMPONENT_NAME;
  img?: string;
  url?: string;
  componentData?: any;
}

export interface SiteWidget extends Widget {
  img: string,
  url: string,
}

export interface WidgetMarketMaterials extends Omit<Widget, 'size'> {
  size: WIDGET_SIZE[];
}

export enum WIDGET_TYPE {
  ICON = 'icon',
  COMPONENT = 'component',
};

export enum WIDGET_COMPONENT_NAME {
  site = 'site',
  todo = 'todo',
  calculator = 'calculator',
  weather = 'weather',
  calendar = 'calendar',
};

export enum WIDGET_SIZE {
  '1x1' = '1x1',
  '2x2' = '2x2',
  '2x4' = '2x4',
};

export enum WIDGET_SIZE_AREA {
  '1x1' = 1,
  '2x2' = 4,
  '2x4' = 8,
};

export enum WIDGET_SIZE_WIDTH {
  '1x1' = 1,
  '2x2' = 2,
  '2x4' = 4,
};

export enum LOAN_TYPE {
  commerce = '0', // 商业贷款
  funds = '1', // 公积金贷款
  hybrid = '2', // 组合贷款
}
export enum REPAYMENT_TYPE {
  debx = '0',
  debj = '1',
}

export type Option = {
  label: string,
  value: string,
};

export interface LOAN_FORM_ITEM {
  name: string,
  unit?: string,
  component?: string,
  label: string,
  placeholder?: string,
  options?: Option[],
  model?: string|number,
}

export type MonthPayItem = {
  leftFund: number; // 剩余本息
  monthName: string; // 月份
  yuebenjin: number; // 月供本金
  yuelixi: number; // 月供利息
};

export interface LoanCalcRes {
  repaymentTotal: number, // 还款总额
  interestTotal: number, // 利息总额
  mouthData: MonthPayItem[], // 月供信息
  repaymentTypeText: string, // 还款方式
}
