function constant(number: number) {
  const arr = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  if (number < 10) {
    return arr[number];
  } if (number % 10 === 0) {
    return `${arr[number / 10]}拾`;
  }
  const ge = number % 10;
  const shi = parseInt(`${number / 10}`);
  return `${arr[shi]}拾${arr[ge]}`;
}
export function formatChineseYear(year: string | any[]) {
  let yearStr = '';
  for (let i = 0; i < year.length; i++) {
    yearStr += constant(Number(year[i]));
  }

  return `${yearStr}年`;
}


export function convertChineseMonthDay(m: string, d: string) {
  const chinese = ['〇', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  let result = '';
  // 月
  if (m.length == 2) {
    let temp = '十';
    if (m.charAt(1) != '0') {
      temp += chinese[Number(m.charAt(1))];
    }
    result += temp;
  } else {
    result += (chinese[Number(m)]);
  }
  result += '月';
  // 日
  if (d.length == 2) {
    let temp = '';
    if (d.charAt(0) != '1') {
      temp += chinese[Number(d.charAt(0))];
    }
    temp += '十';
    if (d.charAt(1) != '0') {
      temp += chinese[Number(d.charAt(1))];
    }
    result += temp;
  } else {
    result += (chinese[Number(d)]);
  }
  return result;
}
