import { authSend } from '@tencent/qb-auth-send-h5';
import QbAPI from './qb-api';
import { BaseUserInfo, ERspCode, GetTabQuickLinksRsp, GetTabsRsp, MigrationTabData, MigrationUserDataRsp, QuickLink, QuickLinkData, RpcResponse, TabInfo, UpdateQuickLinksDataRsp, UpdateQuickLinksRsp, UpdateTabsRsp } from '@/types/tab';
import { isSogou } from './util';
import { CUSTOM_EVENTS } from './constants';

const RPC_SERVANT_NAME = {
  TABS: 'trpc.mtt.pc_tabs_manage.PcTabsManage',
};

// https://trpc.rick.woa.com/rick/pb/view_protobuf?id=14024
const TOKEN_TYPE_MAP = {
  3: 2, // wx
  5: 7, // qq
  2: 8, // ctk token
  4: 11, // sogou下qq&wx
};

const ACCOUNT_TYPE = {
  3: 2, // wx
  5: 4, // qq
  2: 4, // ctk token
  4: 8, // sogou下qq&wx
};

export default class RpcHelper {
  public static async getTabs() {
    const res = (await RpcHelper.fetch(RPC_SERVANT_NAME.TABS, 'GetTabs', {
      with_first_tab_info: true,
    })) as GetTabsRsp;

    return res;
  }

  public static async updateTabs(tabs: TabInfo[], version: number) {
    return (await RpcHelper.fetch(RPC_SERVANT_NAME.TABS, 'UpdateTabs', {
      tabs,
      version,
    }, true)) as UpdateTabsRsp;
  }

  public static async getTabQuickLinks(tabId: string) {
    const res = (await RpcHelper.fetch(RPC_SERVANT_NAME.TABS, 'GetTabQuickLinks', {
      tab_id: tabId,
    })) as GetTabQuickLinksRsp;

    return res;
  }

  public static async updateQuickLinks(tabId: string, quickLinks: QuickLink[], version: number) {
    return (await RpcHelper.fetch(RPC_SERVANT_NAME.TABS, 'UpdateQuickLinks', {
      tab_id: tabId,
      quick_links: quickLinks,
      version,
    }, true)) as UpdateQuickLinksRsp;
  }

  public static async updateQuickLinksData(quickLinkData: QuickLinkData, version: number) {
    return (await RpcHelper.fetch(RPC_SERVANT_NAME.TABS, 'UpdateQuickLinksData', {
      quick_link_data: quickLinkData,
      version,
    }, true)) as UpdateQuickLinksDataRsp;
  }

  public static async migrationUserData(migrationTabs: MigrationTabData[]) {
    return (await RpcHelper.fetch(RPC_SERVANT_NAME.TABS, 'MigrationUserData', {
      migration_tabs: migrationTabs,
    })) as MigrationUserDataRsp;
  }

  private static async fetch(servantName: string, funcName: string, data: Record<string, any>, isRemind = false) {
    try {
      return await RpcHelper.send(servantName, funcName, data);
    } catch (e) {
      const ret = (e as RpcResponse)?.['trpc-func-ret'];
      if (ret === ERspCode.RR_TOKEN_EXPIRED) {
        const { qbid, openid } = await QbAPI.getLoginInfo();
        const isSuccess = await QbAPI.refreshToken(qbid, openid);
        if (isSuccess) {
          return await RpcHelper.send(servantName, funcName, data);
        }

        window.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.TOKEN_EXPIRED));
      }

      if (ret === ERspCode.ERR_VERSION_EXPIRED) {
        window.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.VERSION_EXPIRED));
      } else if (isRemind) {
        window.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.UPDATE_ERROR));
      }

      throw e;
    }
  }

  private static async send(servantName: string, funcName: string, data: Record<string, any>) {
    const userInfo = await RpcHelper.createUserInfo();
    return await authSend({
      servantName,
      funcName: `/${servantName}/${funcName}`,
      url: import.meta.env.VITE_RPC_URL,
    }, {
      base_user_info: userInfo,
      ...data,
    });
  }

  private static async createUserInfo() {
    const { qbid, appid, openid, ctk: token, mode } = await QbAPI.getLoginInfo();
    let newMode = mode;
    if (!newMode && isSogou()) {
      newMode = 4;
    }

    return {
      device_id: QbAPI.getGuid(),
      qbid,
      account: {
        account_type: ACCOUNT_TYPE[newMode as keyof typeof ACCOUNT_TYPE] || ACCOUNT_TYPE[2],
        account_id: openid,
        appid,
      },
      token: {
        token_type: TOKEN_TYPE_MAP[newMode as keyof typeof TOKEN_TYPE_MAP] || TOKEN_TYPE_MAP[2],
        token,
      },
    } satisfies BaseUserInfo;
  }
}
