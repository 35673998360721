<script setup lang="ts">
import { PropType, ref, defineModel, onMounted } from 'vue';

interface SelectorOption {
  label: string,
  value: string,
}

const props = defineProps({
  options: {
    type: Array as PropType<SelectorOption[]>,
    default: () => [],
  },
  defaultValue: {
    type: String,
  },
});

const model = defineModel<string>();
const emits = defineEmits(['onChange']);
const show = ref(false);
const selectorList = ref();

const showList = () => {
  show.value = !show.value;
};

const getLabel = () => {
  const option = props.options.find(option => option.value === model.value);
  return option?.label;
};

const onChange = (option: SelectorOption) => {
  if (option.value === model.value) return;
  show.value = false;
  model.value = option.value;
  emits('onChange', option.value);
};

</script>
<template>
  <div class="selector">
    <div
      class="selector-item"
      @click.prevent.stop="showList"
    >
      <span class="selector-label">{{ getLabel() }}</span>
      <i class="icon-arrow-bottom" />
    </div>
    <div
      v-if="show"
      ref="selectorList"
      class="selector-list"
    >
      <div
        v-for="(option, index) in props.options"
        :key="index"
        :class="{
          'option-item':true,
          'active': model === option.value,
        }"
        @click="onChange(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './selector.less';
</style>
