<template>
  <div
    class="slide-guide"
    @click="onClick"
  >
    <div class="icon-guide" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SlideGuide',
  emits: ['onClick'],
  setup(props, { emit }) {
    const onClick = () => {
      emit('onClick');
    };
    return {
      onClick,
    };
  },
});
</script>
<style lang="less" scoped>
.slide-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  cursor: pointer;
  width: 128px;
  height: 44px;
  border-radius: 100px;
  border: 1px solid rgba(3, 23, 77, 0.1);
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  .icon-guide {
    width: 48px;
    height: 12px;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('https://st.tencent-cloud.com/jax-static/newtab/new-version/icon-slide-guide.png');
  }
  .text {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
