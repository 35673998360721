/* eslint-disable */
/**
 * @file 房贷计算
 */

import { RATE_MAP } from '@/configs/component';
import { MonthPayItem, REPAYMENT_TYPE } from '@/types';
import * as dayjs from 'dayjs';

//等额本息计算
export const benxi = function (num, year, lilv, timeType = 'year') {
  //每月月供额=〔贷款本金×月利率×(1＋月利率)＾还款月数〕÷〔(1＋月利率)＾还款月数-1〕
  var mouth = timeType === 'year' ? parseInt(year) * 12 : year,
    mouthlilv = parseFloat(lilv) / 12,
    dknum = parseFloat(num) * 10000;
  //每月月供
  var yuegong = (dknum * mouthlilv * Math.pow(1 + mouthlilv, mouth)) / (Math.pow(1 + mouthlilv, mouth) - 1);
  //总利息=还款月数×每月月供额-贷款本金
  var totalLixi = mouth * yuegong - dknum;
  //还款总额 总利息+贷款本金
  var totalPrice = totalLixi + dknum,
    leftFund = totalLixi + dknum;

  //循环月份
  var mouthdataArray = [],
    nowmouth = new Date().getMonth(),
    realmonth = 0;

  for (var i = 1; i <= mouth; i++) {
    realmonth = nowmouth + i;
    var yearlist = Math.floor(i / 12);

    realmonth = realmonth - 12 * yearlist;

    if (realmonth > 12) {
      realmonth = realmonth - 12;
    }
    //console.log(realmonth)
    //每月应还利息=贷款本金×月利率×〔(1+月利率)^还款月数-(1+月利率)^(还款月序号-1)〕÷〔(1+月利率)^还款月数-1〕
    var yuelixi = (dknum * mouthlilv * (Math.pow(1 + mouthlilv, mouth) - Math.pow(1 + mouthlilv, i - 1))) / (Math.pow(1 + mouthlilv, mouth) - 1);
    //每月应还本金=贷款本金×月利率×(1+月利率)^(还款月序号-1)÷〔(1+月利率)^还款月数-1〕
    var yuebenjin = (dknum * mouthlilv * Math.pow(1 + mouthlilv, i - 1)) / (Math.pow(1 + mouthlilv, mouth) - 1);
    leftFund = leftFund - (yuelixi + yuebenjin);
    if (leftFund < 0) {
      leftFund = 0;
    }
    mouthdataArray[i - 1] = {
      monthName: realmonth + '月',
      yuelixi: yuelixi,
      yuebenjin: yuebenjin,
      //剩余还款
      leftFund: leftFund,
    };
  }
  return {
    yuegong: Number(yuegong),
    totalLixi: Number(totalLixi),
    totalPrice: Number(totalPrice),
    mouthdataArray: mouthdataArray as MonthPayItem[],
    totalDknum: Number(num),
    year: year,
  };
};
//等额本金计算
export const benjin = function (num, year, lilv, timeType = 'year') {
  var mouth = timeType === 'year' ? parseInt(year) * 12 : year,
    mouthlilv = parseFloat(lilv) / 12,
    dknum = parseFloat(num) * 10000,
    yhbenjin = 0; //首月还款已还本金金额是0
  //每月应还本金=贷款本金÷还款月数
  var everymonthyh = dknum / mouth;
  //每月月供额=(贷款本金÷还款月数)+(贷款本金-已归还本金累计额)×月利率
  var yuegong = everymonthyh + (dknum - yhbenjin) * mouthlilv;
  //每月月供递减额=每月应还本金×月利率=贷款本金÷还款月数×月利率
  var yuegongdijian = everymonthyh * mouthlilv;
  //总利息=〔(总贷款额÷还款月数+总贷款额×月利率)+总贷款额÷还款月数×(1+月利率)〕÷2×还款月数-总贷款额
  var totalLixi = ((everymonthyh + dknum * mouthlilv + (dknum / mouth) * (1 + mouthlilv)) / 2) * mouth - dknum;
  //还款总额 总利息+贷款本金
  var totalPrice = totalLixi + dknum,
    leftFund = totalLixi + dknum;

  //循环月份
  var mouthdataArray = [],
    nowmouth = new Date().getMonth(),
    realmonth = 0;

  for (var i = 1; i <= mouth; i++) {
    realmonth = nowmouth + i;
    var yearlist = Math.floor(i / 12);

    realmonth = realmonth - 12 * yearlist;

    if (realmonth > 12) {
      realmonth = realmonth - 12;
    }
    yhbenjin = everymonthyh * (i - 1);
    var yuebenjin = everymonthyh + (dknum - yhbenjin) * mouthlilv;
    //每月应还利息=剩余本金×月利率=(贷款本金-已归还本金累计额)×月利率
    var yuelixi = (dknum - yhbenjin) * mouthlilv;
    leftFund = leftFund - yuebenjin;
    if (leftFund < 0) {
      leftFund = 0;
    }
    mouthdataArray[i - 1] = {
      monthName: realmonth + '月',
      yuelixi: yuelixi,
      //每月本金
      yuebenjin: everymonthyh,
      //剩余还款
      leftFund: leftFund,
    };
  }
  return {
    yuegong: Number(yuegong),
    totalLixi: Number(totalLixi),
    totalPrice: Number(totalPrice),
    yuegongdijian: Number(yuegongdijian),
    mouthdataArray: mouthdataArray as MonthPayItem[],
    totalDknum: Number(num),
    year: year,
  };
};

// 合并公积金贷款结果和商贷结果
export const mergeRes = (commerceRes, gjjRes) => {
  const mouthdataArray: MonthPayItem[] = [];
  commerceRes.mouthdataArray.forEach((item: MonthPayItem, i) => {
    mouthdataArray.push({
      monthName: item.monthName,
      yuelixi: item.yuelixi + gjjRes.mouthdataArray[i].yuelixi,
      yuebenjin: item.yuebenjin + gjjRes.mouthdataArray[i].yuebenjin,
      leftFund: item.leftFund + gjjRes.mouthdataArray[i].leftFund,
    });
  });
  return {
    yuegong: commerceRes.yuegong + gjjRes.yuegong,
    totalLixi: commerceRes.totalLixi + gjjRes.totalLixi,
    totalPrice: commerceRes.totalPrice + gjjRes.totalPrice,
    yuegongdijian: commerceRes.yuegongdijian + gjjRes.yuegongdijian,
    mouthdataArray: mouthdataArray,
    totalDknum: commerceRes.totalDknum + gjjRes.totalDknum,
    year: commerceRes.year,
  };
};

// 使用商贷总额计算调整后利率
export const calRateByCommerce = (total: number, year: number, curRate: number, newRate: number, startTime: number, type: REPAYMENT_TYPE) => {
  console.log('[贷款总额]', total);
  console.log('[贷款年限]', year);
  console.log('[当前利率]', curRate);
  console.log('[调整后最新利率]', newRate);
  console.log('[贷款开始时间]', startTime);
  console.log('[贷款方式]', type);

  // 首先计算已经还了多少期了。即过了多少个月。以8月31号作为分割线，先算出8月份为止一共还了多少期了。9月份再算一期，最终10月份房贷使用最新利率

  const today = dayjs('2023/08/31');
  let pastMonthCount = today.diff(startTime, 'month', false) + 1 + 1;
  // 在8月31号以后开始还款, 已还款期数要减一天
  if (startTime > new Date('2023/08/31 23:59:59').getTime()) {
    pastMonthCount = pastMonthCount - 1;
  }
  console.log('[已经还款期数]', today.diff(startTime, 'month', false), pastMonthCount);
  const oldPlan = type === REPAYMENT_TYPE.debx ? benxi(total, year, curRate) : benjin(total, year, curRate);
  console.log('[原来的还款计划]', oldPlan);

  // 计算已经还了多少钱了
  const pastMonth = oldPlan.mouthdataArray.slice(0, pastMonthCount);
  const benjinPaid = pastMonth.reduce((sum, v) => {
    sum += v.yuebenjin;
    return sum;
  }, 0);
  const lixiPaid = pastMonth.reduce((sum, v) => {
    sum += v.yuelixi;
    return sum;
  }, 0);
  console.log('[已经还了的钱]', benjinPaid, lixiPaid);

  // 剩余本金
  const leftBenjin = total * 10000 - benjinPaid;

  // 新的还款计划
  const newPlan = type ===  REPAYMENT_TYPE.debx ? benxi(leftBenjin / 10000, year * 12 - pastMonthCount, newRate, 'month') : benjin(leftBenjin / 10000, year * 12 - pastMonthCount, newRate, 'month');

  console.log('[新的还款计划]', newPlan);

  // 计算节省的利息
  const totalLixiNew = newPlan.totalLixi;
  const lixiSaved = oldPlan.totalLixi - (totalLixiNew + lixiPaid);
  console.log('[节省利息]', lixiSaved);

  // 计算每月节省的金额，等额本金也按照第一个月来算
  const leftOldPlan = oldPlan.mouthdataArray.slice(pastMonthCount);
  console.log('[剩余的原还款计划]', leftOldPlan);
  const monthSaved = leftOldPlan[0].yuebenjin + leftOldPlan[0].yuelixi - (newPlan.mouthdataArray[0].yuebenjin + newPlan.mouthdataArray[0].yuelixi);

  return {
    lixiSaved,
    monthSaved,
    leftOldPlan,
    leftNewPlan: newPlan.mouthdataArray,
    startMonth: pastMonthCount + 1,
  };
};

// 直接利用剩余本金和剩余期数进行计算
export const calByLeftFund = (total: number, yearOrMonth: number, curRate: number, newRate: number, type: REPAYMENT_TYPE) => {
  const oldPlan = type === REPAYMENT_TYPE.debx ? benxi(total, yearOrMonth, curRate, 'month') : benjin(total, yearOrMonth, curRate, 'month');
  const newPlan = type === REPAYMENT_TYPE.debx ? benxi(total, yearOrMonth, newRate, 'month') : benjin(total, yearOrMonth, newRate, 'month');
  const lixiSaved = oldPlan.totalLixi - newPlan.totalLixi;
  const monthSaved = oldPlan.mouthdataArray[0].yuebenjin + oldPlan.mouthdataArray[0].yuelixi - (newPlan.mouthdataArray[0].yuebenjin + newPlan.mouthdataArray[0].yuelixi);
  return {
    lixiSaved,
    monthSaved,
    leftOldPlan: oldPlan.mouthdataArray,
    leftNewPlan: newPlan.mouthdataArray,
    startMonth: 1,
  };
};

// 判断是否在日期区间
export const checkInTimeRange = (time: number, range: [number, number | undefined]): boolean => {
  const [startTime, endTime = Date.now()] = range;
  return time >= startTime && time <= endTime;
};

const LPR = '0.042';

// 各城的房贷利率下限。只记录和全国不一样的城
const RATE_MAP = {
  北京: [
    {
      // 利率下限
      lowest: {
        value: '0.0475', // LPR + 0.0055,
        time: [1569859200000], // 2019年10月1号至今
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1569859200000, 1572537599000], // 2019.10.1 - 2019.10.31
      ],
    },
  ],
  长春: [
    {
      lowest: {
        value: '0.0425', // LPR + 0.0005,
        time: [1569859200000, 1653926400000], // 2019.10.1 - 2022.5.31
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1569859200000, 1572537599000], // 2019.10.1 - 2019.10.31
        [1651334400000, 1654012799000], // 2022.5.1 - 2022.5.31
      ],
    },
  ],
  上海: [
    {
      lowest: {
        value: '0.0455', // LPR + 0.0035,
        time: [1658592000000], // 2021.7.24 - 至今
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1656604800000, 1627747199000], // 2021.7.1 - 2021.7.31
      ],
    },
  ],
  杭州: [
    {
      lowest: {
        value: LPR,
        time: [1651334400000, 1669737600000], //  2022.5 - 2022.11
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1651334400000, 1654012799000], // 2022.5.1 - 2022.5.31
        [1667232000000, 1669823999000], // 2022.11.1 - 2022.11.30
      ],
    },
  ],
  厦门: [
    {
      lowest: {
        value: '0.045', // LPR + 0.003,
        time: [1569859200000, 1653926400000], //  2019.10.1 - 2022.5.31
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1569859200000, 1572537599000], // 2019.10.1 - 2019.10.31
        [1651334400000, 1654012799000], // 2022.5.1 - 2022.5.31
      ],
    },
    {
      lowest: {
        value: '0.043', // LPR + 0.001,
        time: [1654012800000, 1661961599000], //  2022.6.1 - 2022.8.31
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1654012800000, 1656604799000], // 2022.6.1 - 2022.6.30
        [1659283200000, 1661961599000], // 2022.8.1 - 2022.8.31
      ],
    },
  ],
  郑州: [
    {
      lowest: {
        value: '0.0425', // LPR + 0.0005,
        time: [1569859200000, 1654012799000], //  2019.10.1 - 2022.5.31
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1569859200000, 1572537599000], // 2019.10.1 - 2019.10.31
        [1556640000000, 1654012799000], // 2019.5.1 - 2022.5.31
      ],
    },
  ],
  广州: [
    {
      lowest: {
        value: LPR,
        time: [1651334400000], //  2022.5.1 - 至今
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1651334400000, 1654012799000], // 2022.5.1 - 2022.5.31
      ],
    },
  ],
  成都: [
    {
      lowest: {
        value: LPR,
        time: [1651334400000, 1675180799000], //  2022.5.1 - 2023.1.31
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1651334400000, 1654012799000], // 2022.5.1 - 2022.5.31
        [1672502400000, 1675180799000], // 2023.1.1 - 2023.1.31
      ],
    },
  ],
  深圳: [
    {
      lowest: {
        value: '0.045', // LPR + 0.003,
        time: [1569859200000], //  2019.10.1 - 至今
      },
      // 如果贷款时间在这个时间段内，则需要进行提示，在该时间段内有利率调整
      tipRange: [
        [1569859200000, 1572537599000], // 2019.10.1 - 2019.10.31
      ],
    },
  ],
};

const searchForRate = (city: any, time: number): { rate: string; hasTip: boolean } => {
  const infoList = RATE_MAP[city];
  if (!infoList) {
    return;
  }
  for (const info of infoList) {
    if (checkInTimeRange(time, info.lowest.time)) {
      const rate = info.lowest.value;
      const hasTip = info.tipRange.some((range) => checkInTimeRange(time, range));
      return {
        rate,
        hasTip,
      };
    }
  }
};

// 获取最新的利率
export const getLatestInterestRate = (location: string, time = Date.now()) => {
  const city = location;

  // 默认的政策为：2019年10月至2022年5月15日为LPR，2022年5月16日至今为LPR-20个BP。2019年以前，由于没有明确的相关政策，按照LPR计算。
  let rate = LPR;
  let hasTip = false;
  // const time2019_10_1 = new Date('2019/10/01').getTime();
  // eslint-disable-next-line
  const time2022_05_16 = new Date('2022/05/16').getTime();
  // eslint-disable-next-line
  if (time < time2022_05_16) {
    rate = LPR;
  } else {
    rate = '0.04'; // LPR - 0.002;
  }

  // 先匹配城，若没有找到最低利率政策，再匹配份，寻找最低利率政策
  const cityMapRes = searchForRate(city, time);
  if (cityMapRes?.rate !== undefined && cityMapRes?.hasTip !== undefined) {
    rate = cityMapRes.rate;
    hasTip = cityMapRes.hasTip;
  }

  return {
    rate,
    hasTip,
  };
};
