<script lang="ts">
import { defineComponent } from 'vue';
import draggable from 'vuedraggable';
import CreateGroupModal from '@/components/modal/create-group/index.vue';
import ContextmenuGroupModal from '@/components/modal/contextmenu-group/index.vue';
import util, { estimateStringWidth, throttle } from '@/utils/util';
import { GROUP_MENU_TEXTS } from '@/configs/quick-chain';
import { GROUP_ICON_LIST } from '@/configs/global';
import deleteModal from '@/plugins/delete-modal';
import { reporter } from '@/lib/dtReport';
import lcLog from '@/lib/lclog';
import { Position } from '@/types';
import AssetHelper from '@/utils/asset-helper';
import { GroupItem } from '@/types/pref';

const MAX_GROUP_SIZE = 8;

export default defineComponent({
  name: 'WidgetGroup',
  components: {
    CreateGroupModal,
    ContextmenuGroupModal,
    draggable,
  },
  props: {
    defaultGroups: {
      type: Array,
      default: () => ([]),
    },
    defaultActiveIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ['select', 'delete', 'create', 'moved'],
  data() {
    return {
      groups: this.defaultGroups,
      cacheGroups: [],
      activeIndex: 0,
      showModal: false,
      showContextGroupModal: false,
      showDeleteModal: false,
      groupContextPosition: null,
      position: null,
      editIndex: -1,
      groupIcons: [],
      showMore: false,
      popupGroups: [],
      popOriginIdx: [],
      showPopUp: false,
      timer: null,
      virtualGroups: [],
    };
  },
  computed: {
    editText() {
      return GROUP_MENU_TEXTS.edit;
    },
    deleteText() {
      return GROUP_MENU_TEXTS.delete;
    },
    showAddIcon() {
      return this.groups.length < MAX_GROUP_SIZE;
    },
    // 编辑分组时使用
    groupItem() {
      return this.groups[this.editIndex] || null;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'group',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    groupDtParams() {
      const isEdit = this.editIndex !== -1;
      const groupName = isEdit ? this.groups[this.editIndex].name : '';
      return {
        group_id: isEdit ? this.editIndex + 1 : this.groups.length + 1,
        group_name: groupName,
        group_position: isEdit ? this.editIndex + 1 : this.groups.length + 1,
        edit_add: isEdit ? 'edit' : 'add',
      };
    },
  },
  watch: {
    groups(newGroups) {
      this.checkOverflow(newGroups);
    },
  },
  created() {
    this.initGroupList();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.$bus.$off('addGroup');
  },
  methods: {
    handleResize() {
      this.checkOverflow(this.groups);
    },
    async initGroupList() {
      try {
        if (this.defaultActiveIndex > 0) {
          this.selectGroup(this.defaultActiveIndex);
        }
        this.groups = this.defaultGroups.slice();
        this.cacheGroups = this.groups;
        this.$nextTick(() => {
          // this.checkOverflow();
          const handleResize = throttle(this.handleResize, 40);
          window.addEventListener('resize', handleResize);
          this.$bus.$on('addGroup', (position: Position) => {
            this.showAddModal(position);
          });
        });
      } catch (e) {
        lcLog.error('[initGroupList]', e);
      }
    },
    selectGroup(index: number) {
      this.activeIndex = index;
      this.$emit('select', index);
    },
    onGroupContextMenu(e: any, index: number) {
      e.preventDefault();
      const rect = e.target.getBoundingClientRect();
      (this.groupContextPosition as Position | null) = {
        top: `${rect.top}px`,
        left: `${rect.left + rect.width + 8}px`,
      };
      this.showContextGroupModal = true;
      this.editIndex = index;
      return false;
    },
    emitEditHandler() {
      this.position = this.groupContextPosition;
      this.showModal = true;
    },
    emitDeleteHandler() {
      this.position = this.groupContextPosition;
      this.showDeleteModal = true;
      const groupName = this.groups[this.editIndex].name;
      const isOnlyOneGroup = this.groups.length === 1;
      let modalContent = `此操作将会删除“${groupName}”分组下的所有内容，确认删除吗？`;
      if (isOnlyOneGroup) {
        modalContent = '删除分组后所有网址也会被删除，可以通过右键重新添加分组和网址';
      }
      deleteModal.show({
        modalValue: true,
        content: modalContent,
        dtParams: {
          group_id: this.editIndex + 1,
          group_name: groupName,
          group_position: this.editIndex + 1,
        },
        confirm: async () => {
          try {
            const newGroups = [...this.groups];
            newGroups.splice(this.editIndex, 1);
            await AssetHelper.updateGroups(newGroups as GroupItem[], [this.editIndex]);

            this.groups = newGroups;
            if (this.editIndex === this.activeIndex) {
              if (this.activeIndex === this.groups.length) {
                this.activeIndex = this.activeIndex - 1 < 0 ? 0 : this.activeIndex - 1;
              } else {
                this.activeIndex = this.activeIndex - 1 < 0 ? 0 : this.activeIndex;
              }
            } else if (this.editIndex < this.activeIndex) {
              this.activeIndex = this.activeIndex - 1 <= 0 ? 0 : this.activeIndex - 1;
            }

            setTimeout(() => {
              this.$emit('delete', this.editIndex, this.groups.length);
            }, 200);
          } catch (e) {
            lcLog.error('emitDeleteHandler', e);
          }
        },
      });
    },
    showAddModalFn(e: MouseEvent) {
      const rect = (e.target as Element).getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      let leftPos = 0;
      const { left, width, top } = rect;
      if (viewportWidth - (left + width) < 270) {
        leftPos = left - 270;
      } else {
        leftPos = left + width + 8;
      }
      this.showAddModal({
        left: `${leftPos}px`,
        top: `${top}px`,
      });
    },
    showAddModal(position: Position) {
      (this.position as Position | null) = position;
      this.editIndex = -1;
      this.showModal = true;
    },
    // 新增/编辑分组
    async saveGroup(group: any) {
      try {
        const newGroups = [...this.groups];
        if (this.groupItem) {
          newGroups[this.editIndex] = { ...this.groupItem, ...group };
        } else {
          newGroups.push(group);
        }

        await AssetHelper.updateGroups(newGroups as GroupItem[]);
        this.groups = newGroups;
        if (!this.groupItem) {
          this.selectGroup(this.groups.length - 1);
          this.$emit('create');
        }

        this.showModal = false;
        this.editIndex = -1;
      } catch (e) {
        lcLog.error('saveGroup', e);
      }
    },
    async updateGroups(groups: any, index: number, isUpdate = false) {
      try {
        if (isUpdate) {
          await AssetHelper.updateGroups(groups as GroupItem[]);
        }

        this.activeIndex = index;
        this.groups = groups;
      } catch (e) {
        lcLog.error('updateGroups', e);
      }
    },
    getGroupIcon(index: number) {
      const { icon } = this.groups[index];
      const group = GROUP_ICON_LIST.find(item => item.name === icon);
      if (this.activeIndex === index) {
        return group?.activeIcon;
      }
      const htmlElement = document.querySelector('html');
      const qblightValue = htmlElement.getAttribute('data-qblight');

      if (qblightValue === 'true') {
        return group?.activeIcon;
      }
      return group?.defaultIcon;
    },
    async onEnd(data) {
      const {
        moved: { newIndex, oldIndex },
      } = data;
      try {
        this.groups = [...this.cacheGroups, ...this.popupGroups];
        await AssetHelper.updateGroups(this.groups as GroupItem[], [oldIndex, newIndex]);
        this.$emit('moved', data.moved);
        const { groups } = this;
        reporter.reportEvent({
          pgid: 'newpage',
          eventName: 'dt_clck',
          businessParams: {
            eid: 'quickstart_group_order',
            group_position: oldIndex + 1,
            group_name: groups[newIndex]?.name,
            group_icon: groups[newIndex]?.icon,
            group_id: oldIndex + 1,
            final_position: newIndex + 1,
          },
        });
      } catch (e) {
        const element = this.groups[newIndex];
        this.groups.splice(newIndex, 1);
        this.groups.splice(oldIndex, 0, element);

        lcLog.error('onEnd', e);
      }
    },
    onMove(event) {
      event.preventDefault();
    },
    getDtParams(group, index) {
      return util.genQueryString({
        group_id: index + 1,
        group_name: group.name,
        group_position: index + 1,
      });
    },
    checkOverflow(newGroups) {
      const ITEM_BOX_WIDTH = 12 * 2 + 20 + 4; // padding*2+icon+gap
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let { width: searchBoxWidth = screenWidth - 120 } = document.querySelector('.search')?.getBoundingClientRect?.();
      this.cacheGroups = [];
      this.popupGroups = [];
      this.popOriginIdx = [];

      newGroups?.forEach((group, index) => {
        searchBoxWidth -= estimateStringWidth(group?.name ?? '') + ITEM_BOX_WIDTH + 8;  // group 8px gap
        if (searchBoxWidth >= 80) { // search 48px padding + more box 32px width
          this.cacheGroups.push(group);
        } else {
          this.popupGroups.push(group);
          this.popOriginIdx.push(index);
        }
      });

      this.showMore = this.cacheGroups.length !== newGroups.length;
    },
    getPopupGroupIcon(index: number) {
      const { icon } = this.popupGroups[index];
      const group = GROUP_ICON_LIST.find(item => item.name === icon);
      return group?.defaultIcon;
    },
    showPopUpFn() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.showPopUp = true;
    },
    hidePopUpFn() {
      this.timer = setTimeout(() => {
        this.showPopUp = false;
      }, 300);
    },
  },
});
</script>
<template>
  <div
    ref="listRef"
    class="group-container"
  >
    <draggable
      v-if="groups.length"
      :list="cacheGroups"
      v-bind="dragOptions"
      class="widget-headers flex"
      itemKey="name"
      @start="onMove"
      @choose="onMove"
      @move="onMove"
      @change="onEnd"
    >
      <template #item="{ element: group, index }">
        <div
          :key="index"
          dt-eid="quickstart_group"
          :dt-params="getDtParams(group, index)"
          :class="['widget-tab-group', 'widget-tab', activeIndex === index ? 'active' : '']"
          @click="selectGroup(index)"
          @contextmenu.prevent.stop="(e) => onGroupContextMenu(e, index)"
        >
          <i
            class="group-icon"
            :style="{ backgroundImage: `url(${getGroupIcon(index)})` }"
          />
          <span class="group-name">{{ group.name }}</span>
        </div>
      </template>
      <template #footer>
        <div
          class="widget-footer"
          @mouseover="showPopUpFn"
          @mouseleave="hidePopUpFn"
        >
          <div
            v-if="showMore"
            class="more-popup"
          >
            <span class="widget-tab tab-more">
              ...&nbsp;更多
            </span>
            <div
              v-if="showPopUp"
              class="popup"
              @mouseover="showPopUpFn"
              @mouseleave="hidePopUpFn"
            >
              <div class="popup-content">
                <div
                  v-for="(item, idx) in popupGroups"
                  :key="idx"
                  :class="['popup-group-item', activeIndex === popOriginIdx[idx] ? 'active' : '']"
                  @click="selectGroup(popOriginIdx[idx])"
                >
                  <i
                    class="group-icon"
                    :style="{ backgroundImage: `url(${getPopupGroupIcon(idx)})` }"
                  />
                  <span class="group-name">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showAddIcon"
            class="widget-tab tab-add"
            dt-eid="quickstart_group_add"
            title="添加分组"
            dt-imp-ignore="true"
            @click="showAddModalFn"
          />
        </div>
      </template>
    </draggable>
    <CreateGroupModal
      v-if="showModal"
      :dtParams="groupDtParams"
      :position="position"
      :groupData="groupItem"
      @add="saveGroup"
      @close="showModal = false"
    />
    <ContextmenuGroupModal
      v-if="showContextGroupModal"
      :position="groupContextPosition"
      :editText="editText"
      :deleteText="deleteText"
      @edit="emitEditHandler"
      @delete="emitDeleteHandler"
      @close="showContextGroupModal = false"
    />
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
