import { createApp } from 'vue';
import ToastManager from './manager.vue';
import { genUuid } from '@/utils/util';
import { ToastType } from './type';

const showToast = (message: string, type: ToastType, duration?: number) => {
  const event = new CustomEvent('addToast', {
    detail: {
      id: genUuid(),
      message,
      type,
      duration,
    },
  });
  window.dispatchEvent(event);
};

const Toast = {
  success: (message: string, duration?: number) => showToast(message,  'success', duration),
  error: (message: string, duration?: number) => showToast(message,  'error', duration),
  info: (message: string, duration?: number) => showToast(message, 'info', duration),
  warning: (message: string, duration?: number) => showToast(message, 'warning', duration),
};

(() => {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const app = createApp(ToastManager);
  app.mount(div);
})();

export default Toast;
