import { createApp } from 'vue';
import skinPlugin from '@tencent/skin-plugin';
import { doReportPv } from '@/lib/lclog';
import { reporter } from '@/lib/dtReport';
import '@/assets/style/reset.css';
import '@/lib/personal';
import './style.less';
import App from './App.vue';
import bus from '@/common/bus';
// import MessagePlugin from './plugins/message';

const bgNode = document.getElementById('qb-bg');
if (bgNode?.style?.backgroundImage === '') {
  skinPlugin.init('#qb-bg');
  document.body.classList.add('no-wallpaper');
}

const app = createApp(App);
// app.use(MessagePlugin);
app.config.globalProperties.$bus = bus;
app.mount('#app');


const screenWidth = window.screen.width;
const screenHeight = window.screen.height;

doReportPv({
  location: window.location.href,
  client: `${window.innerWidth}*${window.innerHeight}`,
  screen: `${screenWidth}*${screenHeight}`,
  pixelRatio: window.devicePixelRatio,
});

reporter.setPage({
  pgid: 'newpage',
}, document.body).observe('newpage');

const handleVersion = () => {
  document.cookie = 'is_new_tab=true; expires=Thu, 01 Jan 2099 00:00:00 GMT; path=/';
  document.cookie = 'mode=2; expires=Thu, 01 Jan 2099 00:00:00 GMT; path=/';
};

// const handleTitle = () => {
//   const isSogou = util.isSogou();
//   if (isSogou) {
//     document.title = '我的最爱';
//   } else {
//     document.title = 'QQ浏览器';
//   }
// };

// handleTitle();
handleVersion();

// const setSkin = () => {
//   const skinStyle = {
//     // 'background-image': `url('https://st.tencent-cloud.com/jax-static/newtab/new-version/74ddff0ace764a868b1e5fb1d68b1beb.jpeg')`,
//     // 'background-image': 'url(\'https://st.tencent-cloud.com/qb/tool/images/dcac7749-737e-46dc-bbe9-2d3c7f3e5d83.png?imageMogr2/format/webp\')',
//     'background-image': 'url(\'https://st.tencent-cloud.com/qb/tool/images/772a080a-45bb-4c78-a42c-ad8c22ffdbc7.png?imageMogr2/format/webp\')',
//     'background-attachment': 'fixed',
//     'background-position': '50% 50%',
//     'background-size': '100% 100%',
//     'background-repeat': 'no-repeat',
//   };
//   const qbBg = document.body;
//   Object.assign((qbBg as HTMLElement).style, skinStyle);
//   document.documentElement.setAttribute('data-qblight', 'false');
// };
// setSkin();
