<script setup lang="ts">
import { PropType, defineModel } from 'vue';
import Selector from './selector.vue';
import Cascader from './cascader.vue';
import Datepicker from './datepicker.vue';

interface SelectorOption {
  label: string,
  value: string,
}

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  component: {
    type: String,
    default: 'input',
  },
  unit: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  isInt: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<SelectorOption[]>,
    default: () => [],
  },
});

const model = defineModel<any>();

const onChange = (val: string) => {
  model.value = val;
};

const onInput = (e) => {
  const val = e.target.value;
  const regex = /^\d+(\.\d+)?$/;
  const validate = regex.test(val);
  if (!validate) {
    model.value = '';
    e.target.value = '';
  }
};

</script>
<template>
  <div class="form-item">
    <div class="form-item-label">
      {{ props.label }}
    </div>
    <div
      v-if="props.component === 'input'"
      class="form-item-control"
    >
      <input
        v-model="model"
        class="form-input"
        type="number"
        min="0"
        pattern="/^\d+(\.\d+)?$/"
        :step="`${isInt ? 1 : undefined}`"
        :placeholder="props.placeholder"
        @input="onInput"
      >
      <span class="unit">{{ props.unit }}</span>
    </div>
    <div
      v-else-if="props.component === 'cascader'"
      class="form-item-control"
    >
      <Cascader
        v-model="model"
        :options="options"
        @onChange="onChange"
      />
    </div>
    <div
      v-else-if="props.component === 'datepicker'"
      class="form-item-control"
    >
      <Datepicker
        v-model="model"
        :options="options"
        @onChange="onChange"
      />
    </div>
    <div
      v-else
      class="form-item-control"
    >
      <Selector
        v-model="model"
        :options="options"
        @onChange="onChange"
      />
    </div>
  </div>
</template>
