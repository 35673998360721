<template>
  <div
    ref="light"
    class="light-container"
    @mouseover="onmouseover"
  >
    <div
      class="icon-light"
      dt-eid="explore"
    />
    <div
      v-if="showLight"
      class="collapse-list"
      dt-eid="explore_panel"
    >
      <div class="title">
        功能探索
      </div>
      <div
        v-for="(light, index) in lightList"
        :key="index"
        :class="['collapse-item', index === activeIndex ? 'active': '']"
      >
        <div
          class="collapse-item__header"
          :dt-params="getDtPrams({
            area: 'title',
            title: light.title
          })"
          dt-eid="explore_content"
          @click="(event) => onCollapseClick(index, event)"
        >
          <span
            class="icon"
            :style="{ backgroundImage: `url(${light.icon})` }"
          />
          <span class="label">{{ light.title }}</span>
          <span class="icon-more" />
        </div>
        <div class="collapse-content">
          <a
            v-for="(subLight, index) in (light.children || [])"
            :key="index"
            class="collapse-content__panel"
            :dt-params="getDtPrams({
              area: 'content',
              jump_url: subLight.url,
              title: subLight.subtitle,
            })"
            dt-eid="explore_content"
            :href="subLight.url"
            :style="{pointerEvents: (subLight.url ? 'auto' : 'none') }"
            target="__blank"
          >
            <p class="collapse-content__title">{{ subLight.subtitle }}</p>
            <p class="collapse-content__content">{{ subLight.content }}</p>
            <div
              v-if="subLight.img"
              class="collapse-content__img"
            >
              <img :src="subLight.img">
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import util from '@/utils/util';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LightComp',
  data() {
    return {
      lightList: [],
      activeIndex: -1,
      showLight: false,
      timer: null,
    };
  },
  created() {
    this.getLight();
  },
  mounted() {
    document.body.addEventListener('click', this.handleBodyClick);
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  },
  methods: {
    getDtPrams(params = {}) {
      return util.genQueryString(params);
    },
    onCollapseClick(index, event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },
    handleBodyClick(event) {
      const hasDom = this.$refs.light.contains(event.target);
      if (!hasDom) {
        this.showLight = false;
      }
    },
    async getLight() {
      $.ajax({
        url: `${window.__API_URL__}/new_api/getLight`,
        success: (data) => {
          this.formatLightList(data.data);
        },
      });
    },
    formatLightList(list) {
      const result = [];
      list.forEach((light) => {
        const { parent_id: parentId } = light;
        const lIndex = result.findIndex(item => item.id === parentId);
        if (lIndex > -1) {
          result[lIndex].children.push(light);
        } else {
          result.push({
            ...light,
            children: [light],
          });
        }
      });
      this.lightList = result;
    },
    onmouseover() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.showLight = true;
    },
    onmouseleave() {
      this.timer = setTimeout(() => {
        this.showLight = false;
        this.activeIndex = -1;
      }, 500);
    },
  },
});
</script>
<style lang="less" scoped>
@import './index.less';
</style>
