<template>
  <div class="header">
    <div class="header-left" />
    <div class="header-right header-right-visibility">
      <div
        ref="parent"
        class="quick-box"
        @mouseover="showQuickKey"
        @mouseleave="hideQuickKey"
      >
        <div class="header-btn btn-quick">
          快捷键
        </div>
        <div
          v-show="showQuickKeyLst"
          class="quick-list modal-box-shadow"
          dt-keep-report="true"
          dt-eid="shortcut_list"
          @mouseover="showQuickKey"
          @mouseleave="hideQuickKey"
        >
          <p class="title">
            快捷键一览
          </p>
          <div class="quick-key-list">
            <div
              v-for="(quick, index) in quickKeys"
              :key="index"
              class="quick-key"
            >
              <span class="label">{{ quick.label }}</span>
              <span class="key">{{ quick.key }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="header-btn btn-change"
        title="切换模式"
        @mouseover="showMore"
        @mouseleave="hideMore"
      >
        切换
      </div>

      <div
        class="header-btn btn-feedback"
        dt-eid="feedback_entry"
        title="反馈"
        @click="feedbackClick()"
        @click.right="emitRestore()"
      >
        反馈
      </div>
      <div class="blank" />
      <div
        class="header-btn btn-skin btn-option"
        dt-eid="cloth"
        title="换肤"
        @click="skinClick()"
      />
      <div
        id="eye"
        :class="['header-btn', showOperation? 'btn-eyes' : 'btn-eyes-close']"
        dt-eid="eye"
        :dt-params="operateDtParams"
        title="隐藏"
        @click="operateHide()"
      />
      <div
        v-if="showMoreList"
        class="menu-children modal-box-shadow"
        @mouseover="showMore"
        @mouseleave="hideMore"
      >
        <div
          v-for="(mode, index) in modeOptions || []"
          :key="index"
          class="menu-item"
          dt-eid="mode_swift"
          :dt-params="mode.dtParams"
          :data-mode="mode.mode"
          @click="modeClick(mode)"
        >
          <span
            class="icon"
            :style="{ opacity: currentMode === mode.mode ? 1 : 0 }"
          />
          <span class="label">{{ mode.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import util, { isSogou } from '@/utils/util';
import restoreModal from '@/plugins/restore-modal';
import { defineComponent } from 'vue';
import AssetHelper from '@/utils/asset-helper';
import { openSkinSidebar } from '@/lib/skin';

export default defineComponent({
  name: 'RightHeader',
  data() {
    return {
      quickKeys: [],
      showQuickKeyLst: false,
      showMoreList: false,
      timer: null,
      keyboardTimer: null,
      showOperation: !!window.isShowOperation,
    };
  },
  computed: {
    modeOptions() {
      const isSogou = util.isSogou();
      return isSogou ? [
        {
          label: '自定义模式',
          key: '2',
          mode: 2,
          url: '/new_pure',
          dtParams: util.genQueryString({
            page_id: 'quickstart',
          }),
        },
        {
          label: '常用模式',
          key: '0',
          mode: 0,
          url: '/grid',
          dtParams: util.genQueryString({
            page_id: 'recentlyused',
          }),
        },
      ] : [
        {
          label: '自定义模式',
          key: '2',
          mode: 2,
          url: '/new_pure',
          dtParams: util.genQueryString({
            page_id: 'quickstart',
          }),
        },
        {
          label: '常用模式',
          key: '0',
          mode: 0,
          url: '/grid',
          dtParams: util.genQueryString({
            page_id: 'recentlyused',
          }),
        },
      ];
    },
    currentMode() {
      let mode = 1;
      switch (window.location.pathname) {
        case '/grid': {
          mode = 0;
          break;
        }
        case '/new_pure/': {
          mode = 2;
          break;
        }
        case '/newpure/': {
          mode = 2;
          break;
        }
        case '/new_pure': {
          mode = 2;
          break;
        }
        case '/newpure': {
          mode = 2;
          break;
        }
        case '/pure': {
          mode = 2;
          break;
        }
        default: {
          const match = document.cookie.match(/mode=(\d);?/i);
          if (match && match[1]) {
            mode = window.parseInt(match[1], 10);
          }
          break;
        }
      }
      return mode;
    },
    operateDtParams() {
      return util.genQueryString({
        open_close: this.showOperation ? 'open' : 'close',
      });
    },
  },
  watch: {
    showOperation(isShowOperation) {
      document.documentElement.style.setProperty('--display-flex-none', isShowOperation ? 'flex' : 'none');
    },
  },
  created() {
    this.initShowOperation();
    this.getHotKeyList();
  },
  methods: {
    initShowOperation() {
      const showStr = window.localStorage.getItem('SHOW_RIGHT_OPERATION');
      if (showStr === null || showStr === 'true') {
        this.showOperation = true;
      } else {
        this.showOperation = false;
      }
      this.$emit('showUserData', {
        show: this.showOperation,
        animate: false,
      });
    },
    getHotKeyList() {
      $.ajax({
        url: `${window?.__API_URL__}/new_api/getHotKeyList`,
        success: (data) => {
          this.quickKeys = data.data;
        },
      });
    },
    showQuickKey() {
      if (this.keyboardTimer) {
        clearTimeout(this.keyboardTimer);
      }
      this.showQuickKeyLst = true;
      this.showMoreList = false;
    },
    hideQuickKey() {
      this.keyboardTimer = setTimeout(() => {
        this.showQuickKeyLst = false;
      }, 300);
    },
    showMore() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.showMoreList = true;
    },
    hideMore() {
      this.timer = setTimeout(() => {
        this.showMoreList = false;
      }, 300);
    },
    skinClick() {
      openSkinSidebar();
    },
    feedbackClick() {
      let feedbackUrl = 'https://browser.qq.com/new/common/aisee.html?fid=38';
      if (isSogou()) {
        feedbackUrl = 'https://feedback.browser.qq.com/sogoubrowser';
      }
      window.open(feedbackUrl);
    },
    modeClick(child) {
      const { mode, url } = child;
      document.cookie = `mode=${mode}; isNewVersionNewTab=true; expires=Thu, 01 Jan 2099 00:00:00 GMT; path=/`;
      window.location.href = url;
    },
    operateHide() {
      this.showOperation = !this.showOperation;
      window.localStorage.setItem('SHOW_RIGHT_OPERATION', `${this.showOperation}`);
      this.$emit('showUserData', {
        show: this.showOperation,
        animate: true,
      });
    },
    emitRestore() {
      restoreModal.show({
        modalValue: true,
        deleteText: '恢复历史数据',
        content: '此操作将会恢复您本地存储的历史网址导航数据。确认恢复吗？',
        confirm: () => {
          AssetHelper.restoreHistoryGroupsAndSites().then((res) => {
            this.$emit('restore', res);
          });
        },
      });
    },
  },
});
</script>
<style lang="less" scoped>
@import './index.less';
</style>
