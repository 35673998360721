import Toast from '@/components/toast';
import restoreModal from '@/plugins/restore-modal';
import { CUSTOM_EVENTS } from '@/utils/constants';
import { onMounted, onUnmounted } from 'vue';

export default function useErrorMessage() {
  const handleShowRefreshMessage = () => {
    restoreModal.show({
      modalValue: true,
      deleteText: '错误',
      okText: '刷新',
      content: '网络出错，请刷新重试',
      confirm: () => {
        window.location.reload();
      },
    });
  };

  const handleShowUpdateErrorMessage = () => {
    Toast.error('操作失败，请稍后再试');
  };

  const handleShowReLoginMessage = () => {
    Toast.warning('请重新登录');
  };

  onMounted(() => {
    window.addEventListener(CUSTOM_EVENTS.VERSION_EXPIRED, handleShowRefreshMessage);
    window.addEventListener(CUSTOM_EVENTS.UPDATE_ERROR, handleShowUpdateErrorMessage);
    window.addEventListener(CUSTOM_EVENTS.TOKEN_EXPIRED, handleShowReLoginMessage);
  });

  onUnmounted(() => {
    window.removeEventListener(CUSTOM_EVENTS.VERSION_EXPIRED, handleShowRefreshMessage);
    window.removeEventListener(CUSTOM_EVENTS.UPDATE_ERROR, handleShowUpdateErrorMessage);
    window.removeEventListener(CUSTOM_EVENTS.TOKEN_EXPIRED, handleShowReLoginMessage);
  });
}
