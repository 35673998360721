<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import util from '@/utils/util';
import type { PropType, CSSProperties } from 'vue';

const MENU_WIDTH = 100;
const MENU_HEIGHT = 110;

interface Position {
  left: string;
  top: string;
}

interface MenuListProps {
  label: string;
  desc?: string;
  icon?: string;
  action: (item: MenuListProps, index: number) => void;
}

const props = defineProps({
  modalValue: Boolean,
  position: {
    type: Object as PropType<Position> | null,
    default: () => ({}),
  },
  list: {
    type: Array as PropType<MenuListProps[]>,
    default: () => [],
  },
  reportParams: {
    type: Object,
    default: () => ({}),
  },
});

const visible = ref(false);


const modalStyle: CSSProperties = {
  left: parseInt(props.position.left, 10) + MENU_WIDTH >= window.innerWidth ? `${window.innerWidth - MENU_WIDTH}px` : props.position.left,
  top: parseInt(props.position.top, 10) + MENU_HEIGHT >= window.innerHeight ? `${window.innerHeight - MENU_HEIGHT}px` : props.position.top,
};

const reportPramsStr = util.genQueryString(props.reportParams);

const emit = defineEmits(['close', 'edit', 'delete']);

const close = () => {
  visible.value = false;
  emit('close');
};

defineExpose({
  visible,
});
</script>
<template>
  <div
    v-if="visible"
    class="modal-wrapper"
  >
    <div
      class="modal-container"
    >
      <div
        class="mask"
        @click="close"
      />
      <div
        class="modal-content"
        :style="modalStyle"
      >
        <button
          v-for="(item, index) in props.list"
          :key="index"
          class="modal-btn"
          :dt-params="reportPramsStr"
          @click="item.action(item, index)"
        >
          <div class="label-item">
            <i
              v-if="item.icon"
              class="icon"
            />
            <span class="label">{{ item.label }}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
