import { isNativeSidebarOpened, isSupportNativeSidebar, openNativeSidebar } from '@/utils/util';
import lcLog from './lclog';

const SKIN_SIDEBAR_URL = 'https://skin.browser.qq.com/?mode=sidebar';

export const openSkinSidebar = async () => {
  if (!isSupportNativeSidebar()) {
    $('#personal .btn-option').click();
    return;
  }

  try {
    const isOpened = await isNativeSidebarOpened(SKIN_SIDEBAR_URL);
    if (!isOpened) {
      await openNativeSidebar(SKIN_SIDEBAR_URL);
    }
  } catch (e) {
    $('#personal .btn-option').click();
    lcLog.error('[openSkinSidebar]', e);
  }
};

