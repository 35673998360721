<script setup lang="ts">
import { MonthPayItem } from '@/types';
import { PropType } from 'vue';

const props = defineProps({
  savedTotal: Number,
  savedEveryMonth: Number,
  oldPlan: Array,
  newPlan: Array,
  rate: [String, Number],
  startMonth: Number,
});

const emits = defineEmits(['back']);
const goBack = () => {
  emits('back');
};
</script>
<template>
  <div class="house-loan-result-panel">
    <button
      class="btn-back"
      @click="goBack"
    />
    <div class="panel-body">
      <div class="panel-row">
        <div class="panel-cell panel-cell-label">
          预计降息后的利率
        </div>
        <div class="panel-cell panel-cell-label">
          预计每月节省金额
        </div>
        <div class="panel-cell panel-cell-label">
          预计总计节省金额
        </div>
      </div>
      <div class="panel-row mt24 panel-table-body">
        <div class="panel-cell">
          {{ `${props.rate ? (props.rate * 100).toFixed(2) : '-'}%` }}
        </div>
        <div class="panel-cell">
          {{ props.savedEveryMonth.toFixed(2) }}
        </div>
        <div class="panel-cell">
          {{ props.savedTotal.toFixed(2) }}
        </div>
      </div>
    </div>
    <div class="panel-table">
      <table class="table">
        <thead>
          <tr>
            <th class="panel-table-cell">
              期数
            </th>
            <th class="panel-table-cell">
              原月供本金
            </th>
            <th class="panel-table-cell">
              原月供利息
            </th>
            <th class="panel-table-cell">
              最新月供本金
            </th>
            <th class="panel-table-cell">
              最新月供利息
            </th>
            <th class="panel-table-cell">
              最新剩余本息
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr
            v-for="(plan, index) in props.oldPlan"
            :key="index"
            class="panel-table-row"
          >
            <td class="panel-table-cell">
              {{ props.startMonth + index }}
            </td>
            <td class="panel-table-cell">
              {{ plan.yuebenjin.toFixed(2) }}
            </td>
            <td class="panel-table-cell">
              {{ plan.yuelixi.toFixed(2) }}
            </td>
            <td class="panel-table-cell">
              {{ props.newPlan[index].yuebenjin.toFixed(2) }}
            </td>
            <td class="panel-table-cell">
              {{ props.newPlan[index].yuelixi.toFixed(2) }}
            </td>
            <td class="panel-table-cell">
              {{ props.newPlan[index].leftFund.toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style lang="less" scope>
@import './loan-result.less';
</style>
