import util from '@/utils/util';
import { doReport } from '@/lib/lclog';

(function (global) {
  // 当前elt滚动
  $.fn.scrollUnique = function () {
    return $(this).each(function () {
      let eventType = 'mousewheel';
      // 火狐是DOMMouseScroll事件
      if (document.mozHidden !== undefined) {
        eventType = 'DOMMouseScroll';
      }
      $(this).on(eventType, function (event) {
        // 一些数据
        const { scrollTop } = this;
        const { scrollHeight } = this;
        const height = this.clientHeight;

        const delta = (event?.originalEvent?.wheelDelta)
          ? event.originalEvent.wheelDelta : -(event?.originalEvent?.detail || 0);

        if ((delta > 0 && scrollTop <= delta) || (delta < 0 && scrollHeight - height - scrollTop <= -1 * delta)) {
          // IE浏览器下滚动会跨越边界直接影响父级滚动，因此，临界时候手动边界滚动定位
          this.scrollTop = delta > 0 ? 0 : scrollHeight;
          // 向上滚 || 向下滚
          event.preventDefault();
        }
      });
    });
  };

  const g = {
    lsKey: 'qa_skin',
    skinId: '',
    skinData: undefined,
  };

  class Personal {
    static isHighVersion() {
      let isHight = false;
      try {
        const version = global.external.getVersion().split('.');
        if (version) {
          const major = parseInt(version[0]);
          const minor = parseInt(version[1]);
          const patch = parseInt(version[2]);
          if (major > 10 || (major === 10 && minor > 3) || (major === 10 && minor === 3 && patch >= 2972)) {
            isHight = true;
          }
        }
      } catch (error) {

      }
      return isHight;
    }

    static getSkinData(url) {
      return new Promise((resolve) => {
        $.ajax({
          url,
          timeout: 5000,
          type: 'GET',
          dataType: 'json',
        }).done((res) => {
          if (res && res.ret === 0) {
            return resolve(res.data);
          }
          return resolve([]);
        })
          .fail((e) => {
            console.log('get data error:', e);
            return resolve([]);
          });
      });
    }

    static async getData() {
      try {
        let data = JSON.parse(window.localStorage.getItem(g.lsKey));
        if (!data || Date.now() - data.timestamp > 1000 * 60 * 10) {
          data = {
            timestamp: Date.now(),
            group: [],
            groupMap: {},
            skinMap: {},
          };
          const [groups, skins] = await Promise.all([
            Personal.getSkinData('https://skin.browser.qq.com/api/get_group?qbver=10'),
            Personal.getSkinData('https://skin.browser.qq.com/api/get_skin?qbver=10')]);
          if (groups.length && skins.length) {
            data.group = groups;
            skins.map((n) => {
              const m = {
                cnname: n.cnname,
                enname: n.enname,
                generated_picture: n.generated_picture,
                group: n.group,
                id: n.id,
                sticker_id: n.sticker_id,
              };
              data.groupMap[n.group] ? data.groupMap[n.group].push(m) : data.groupMap[n.group] = [m];
              // 查找时省得遍历了
              data.skinMap[n.id] = n;
            });
            localStorage.setItem(g.lsKey, JSON.stringify(data));
          }
        }
        g.skinData = data;
        return data;
      } catch (e) {
        return null;
      }
    }

    static buildSkinHead(arr, groupId = arr?.[0].id) {
      return  `
      ${arr.slice(0, 9).map((n, index) => {
    const dtParams = `name=${encodeURIComponent(n.name)}&position=${index + 1}`;
    return `<a dt-eid="cloth_tab" dt-imp-ignore="true" dt-imp-ignore="true" dt-params="${dtParams}" class="group-item${n.id === groupId ? ' active' : ''}" data-group-id="${n.id}">${n.name}</a>`;
  })
    .join('')}
      `;
    }

    static buildSkinBody(arr) {
      const { skinId } = g;
      return arr && Array.isArray(arr) ? `
        ${arr.map((n, index) => {
    const dtParams = `pic_id=${n.id}&position=${index + 1}&name=${n.cnname}&group_id=${n.group}`;
    return `
          <div dt-eid="cloth_pic" dt-imp-ignore="true" dt-params="${dtParams}" class="skin-item${n.id === skinId ? ' active' : ''}" data-skin-id="${n.id}" data-skin-name="${n.cnname}" style="background-image: url(${n.generated_picture})">
            <div class="btn-group">
              <div class="btn-skin btn-set-skin">点击换肤</div>
              <div class="btn-skin btn-set-desk">设为桌面壁纸</div>
            </div>
          </div>`;
  }).join('')}
      ` : '确认下是不是测试数据';
    }

    static buildNav(list = [0, 1, 2]) {
      let mode = 1;
      const eid = 'change';
      switch (global.location.pathname) {
        case '/grid': {
          mode = 0;
          break;
        }
        case '/pure': {
          mode = 2;
          break;
        }
        default: {
          const hasMode = document.cookie.match(/mode=(\d);?/i);
          if (hasMode && hasMode[1]) {
            mode = global.parseInt(hasMode[1], 10);
          }
          break;
        }
      }
      return `
        ${list.includes(2) ? `<a dt-eid="${eid}" dt-imp-ignore="true" dt-params="${util.genQueryString({
    name: 'pure',
    position: 1,
  })}" href="/pure" class="icon-search-default btn-search-tab${mode === 2 ? ' active' : ''}" data-mode="2"></a>` : ''}
        ${list.includes(0) ? `<a dt-eid="${eid}" dt-imp-ignore="true" dt-params="${util.genQueryString({
    name: 'grid',
    position: 2,
  })}" href="/grid" class="icon-grid-default btn-quick-access${mode === 0 ? ' active' : ''}" data-mode="0"></a>` : ''}
        ${list.includes(1) ? `<a dt-eid="${eid}" dt-imp-ignore="true" dt-params="${util.genQueryString({
    name: 'newtab',
    position: 3,
  })}" href="/newtab" class="icon-newtab-default btn-favorite-tab${mode === 1 ? ' active' : ''}" data-mode="1"></a>` : ''}
        <div class="btn-options-block">
          <a class="btn-option icon-skin-default" dt-eid="cloth" dt-imp-ignore="true" href="javascript:;"></a>
        </div>
      `;
    }

    static async initDom(list) {
      console.log('>>> initDOM');
      const [data, curSkin] = await Promise.all([Personal.getData(), new Promise((resolve) => {
        if (Personal.isHighVersion()) {
          global.qbskin.get((d) => {
            resolve(d);
          });
        } else {
          resolve({
            id: 0,
          });
        }
      })]);
      g.skinId = curSkin.id;
      const templateStr = `
        <div id="personal" class="off">
          <div class="menu" oncontextmenu="return!1">
            ${Personal.buildNav(list)}
          </div>
          <div class="list">
            <div class="skin-title">
              <span>主题</span>
              <span dt-imp-ignore="true" dt-eid="cloth_recover" class="reset-skin">恢复默认皮肤</span>
            </div>
            <div class="skin-tab">
              <div class="skin-tab-head">
                ${Personal.buildSkinHead(data.group)}
                <a class="more-skin" dt-imp-ignore="true" dt-eid="cloth_more">更多皮肤</a>
              </div>
              <div class="skin-tab-body">
                ${Personal.buildSkinBody(data.groupMap[data.group[0].id])}
              </div>
            </div>
          </div>
        </div>
      `;
      $('body').append(templateStr);
    }

    static initEvent() {
      // 打开关闭
      const btnJQ = $('#personal .btn-option');
      btnJQ.on('click', () => {
        const eltJQ = $('#personal');
        const oldTips = $('#personal .old-version-tips');
        eltJQ.addClass('process');
        oldTips.addClass('old-version-tips-display');
        eltJQ.toggleClass('on').toggleClass('off');
        doReport({ eid: 'btn-skin' });
        setTimeout(() => {
          eltJQ.removeClass('process');
        }, 500);
      });

      // 切换tab
      $('#personal').on('mousedown', 'a[data-mode]', (e) => {
        const eltJQ = $(e.target);
        // 设置cookie
        document.cookie = `mode=${eltJQ.attr('data-mode')}; expires=Thu, 01 Jan 2099 00:00:00 GMT; path=/`;
      });

      // 切换skin group
      $('#personal').on('click', '.group-item', (e) => {
        const eltJQ = $(e.target);
        const listJQ = $('#personal .skin-tab-body');
        eltJQ.addClass('active').siblings()
          .removeClass('active');
        listJQ.html(Personal.buildSkinBody(g.skinData.groupMap[eltJQ.attr('data-group-id')]));
      });
      // 切换skin id
      $('#personal').on('click', '.skin-item', function (e) {
        const eltJQ = $(this);
        eltJQ.addClass('active').siblings()
          .removeClass('active');
        g.skinId = eltJQ.attr('data-skin-id');
        global.qbskin.set(g.skinData.skinMap[g.skinId]);
        doReport({ eid: 'use-skin', skinId: g.skinId, skinName: eltJQ.attr('data-skin-name') });
        if ($(e.target).hasClass('btn-set-desk')) {
          doReport({ eid: 'use-desk', skinId: g.skinId, skinName: eltJQ.attr('data-skin-name') });
          setTimeout(() => {
            util.setScreenBg();
          }, 200);
        }
      });
      // 更多skin
      $('#personal').on('click', '.more-skin', () => {
        doReport({ eid: 'more-skin', location: window.location.href });
        global.external.NavigateToBuiltinPath('skin', 4);
      });
      // 恢复默认skin
      $('#personal').on('click', '.reset-skin', () => {
        $('#personal .skin-item').removeClass('active');
        global.qbskin.reset();
        document.documentElement.setAttribute('data-qblight', 'false');
        doReport({ eid: 'reset-skin' });
      });
      // 防止额外滚动
      $('#personal .list').scrollUnique();
      // 自动收起
      $(document).on('click', (e) => {
        const eltJQ = $('#personal');
        const pos = eltJQ[0].compareDocumentPosition(e.target);
        if (!pos || (pos & 16)) {
          return;
        }
        if (eltJQ.hasClass('on') && !eltJQ.hasClass('process')) {
          $('#personal .btn-option').click();
        }
      });
      // 滚动时隐藏, 菜单隐藏
      $(window).on('scroll', () => {
        const elt = $('#personal');
        if (document.documentElement.scrollTop > 10) {
          elt.hide();
        } else {
          elt.show();
        }
      });
    }
  }

  global.personal = {
    async init(list: number[]) {
      await Personal.initDom(list);
      Personal.initEvent();
    },
    isHighVersion: Personal.isHighVersion,
  };

  // 跳转逻辑
  if (Personal.isHighVersion() && !/mode=\d;?/.test(document.cookie)) {
    window.chrome?.prefs?.get?.('NewTab.Mode', (mode) => {
      if (mode !== '1') {
        doReport({
          action: 'reloadPage',
        });
        // 设置cookie
        document.cookie = `mode=${mode}; expires=Thu, 01 Jan 2099 00:00:00 GMT; path=/`;
        location.reload();
      }
    });
  }
  // qb有效
  if (util.isQB()) {
    global.personal.init();
  }
  // 支持融合版
  if (util.isSogou()) {
    console.log('融合版');
    global.personal.init([0, 2]);
  }
}(window));
