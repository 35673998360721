<!-- 利率调整计算 -->
<script setup lang="ts">
import { LOAN_FORM_ITEM, REPAYMENT_TYPE } from '@/types';
import { computed, reactive, ref } from 'vue';
import FormItem from './form-item.vue';
import Tabs from '../tabs/index.vue';
import { BLANK_LIST, CITY_LIST } from '@/configs/component';
import { calByLeftFund, calRateByCommerce, getLatestInterestRate } from '@/lib/mortgage';
import LoanRateResult from './loan-rate-result.vue';

interface FormState {
  bank: string,
  city: string,
  time: number|string,
  commerceRate: number;
  rate: string;
  repaymentType: REPAYMENT_TYPE;
  calcType: string,
  commerceTotal: string;
  loanYears: number;
  leftFunds: number|string,
  leftMonth: number|string,
}

const repaymentOptions = [
  {
    label: '等额本息',
    value: REPAYMENT_TYPE.debx,
  },
  {
    label: '等额本金',
    value: REPAYMENT_TYPE.debj,
  },
];

const calcTypeTabs = [
  {
    label: '使用商贷总额',
    value: 'useCommerce',

  }, {
    label: '使用剩余本金',
    value: 'userPrincipal',
  },
];

const activeTab = ref('useCommerce');
const onTabClick = (val: string) => {
  activeTab.value = val;
  formState.calcType = val;
};
const curDate = new Date();
const formState = reactive<FormState>({
  bank: BLANK_LIST[0],
  city: CITY_LIST[0].value,
  time: `${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDate()}`,
  commerceRate: 4.9, // 当前贷款利率
  rate: '', // 调整后的利率
  repaymentType: REPAYMENT_TYPE.debx, // 还款方式
  calcType: 'useCommerce',

  commerceTotal: '', // 商贷总额z
  loanYears: 30, // 贷款年限

  leftFunds: '', // 剩余本金
  leftMonth: '', // 剩余期数
});

const options = computed<LOAN_FORM_ITEM[]>(() => [
  {
    label: '贷款银行',
    name: 'bank',
    component: 'selector',
    options: BLANK_LIST.map(item => ({
      label: item,
      value: item,
    })),
  },
  {
    label: '贷款时间',
    name: 'time',
    component: 'datepicker',
  },
  {
    label: '贷款城市',
    name: 'city',
    component: 'cascader',
    options: CITY_LIST,
  },
  {
    label: '贷款利率',
    name: 'commerceRate',
    unit: '%',
    placeholder: '请输入贷款利率',
    isInt: true,
  },
  {
    label: '贷款方式',
    name: 'repaymentType',
    component: 'selector',
    options: repaymentOptions,
  },
]);

const calcTypeOptions = computed(() => {
  const commerceOptions = [
    {
      label: '商贷总额',
      name: 'commerceTotal',
      unit: '万',
      placeholder: '请输入贷款总额',
    },
    {
      label: '贷款年限',
      name: 'loanYears',
      unit: '年',
      placeholder: '请输入贷款年限',
      component: 'selector',
      options: Array.from({ length: 30 }, (_, index) => {
        const number = index + 1;
        return {
          label: `${number}年`,
          value: number,
        };
      }),
    },
  ];
  const principalOptions = [
    {
      label: '剩余本金',
      name: 'leftFunds',
      unit: '万',
      placeholder: '请输入剩余本金',
    },
    {
      label: '剩余期数',
      name: 'leftMonth',
      unit: '月',
      placeholder: '请输入剩余期数',
    },
  ];
  let options = commerceOptions;
  if (formState.calcType === 'useCommerce') {
    options = commerceOptions;
  } else {
    options = principalOptions;
  }
  return options;
});

const showResult = ref(false);
const loadResult = ref<any>({});
const startCalc = () => {
  const { rate } = getLatestInterestRate(formState.city, new Date(formState.time).getTime());
  const finalRate = Math.min(
    Number((Number(formState.commerceRate) / 100).toFixed(4)),
    Number(rate),
  );
  // cal({
  //   total: formState.commerceTotal,
  // });
  // displayResult();
  let result = {};
  if (formState.calcType === 'useCommerce') {
    result = calRateByCommerce(
      formState.commerceTotal,
      formState.loanYears,
      Number(formState.commerceRate) / 100,
      finalRate,
      new Date(formState.time).getTime(),
      formState.repaymentType,
    );
  } else {
    result = calByLeftFund(
      formState.leftFunds,
      formState.leftMonth,
      Number(formState.commerceRate) / 100,
      finalRate,
      formState.repaymentType,
    );
  }
  loadResult.value = {
    savedTotal: result.lixiSaved,
    savedEveryMonth: result.monthSaved,
    oldPlan: result.leftOldPlan,
    newPlan: result.leftNewPlan,
    rate: finalRate,
    startMonth: result.startMonth,
  };
  displayResult();
};

const handleSubmit = (e: Event) => {
  e.preventDefault();
  startCalc();
};
const emits = defineEmits(['display']);
const displayResult = () => {
  showResult.value = !showResult.value;
  emits('display', !showResult.value);
};

</script>
<template>
  <div class="loan-rate-form">
    <form
      v-show="!showResult"
      novalidate
      class="form"
      @submit="handleSubmit"
    >
      <FormItem
        v-for="option in options"
        :key="option.name"
        v-bind="option"
        v-model:modelValue="formState[option.name as keyof FormState]"
      />
      <Tabs
        v-model="activeTab"
        style="margin-top: 52px"
        :options="calcTypeTabs"
        @change="onTabClick"
      />
      <FormItem
        v-for="option in calcTypeOptions"
        :key="option.name"
        v-bind="option"
        v-model:modelValue="formState[option.name as keyof FormState]"
      />
      <div class="form-item no-style">
        <button class="form-item-button">
          开始计算
        </button>
      </div>
    </form>
    <LoanRateResult
      v-if="showResult"
      v-bind="loadResult"
      @back="displayResult"
    />
  </div>
</template>
<style lang="less" scoped>
@import './loan-rate.less';
</style>
