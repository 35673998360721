<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import util from '@/utils/util';

const props = defineProps({
  content: String,
  deleteText: {
    type: String,
    default: '删除',
  },
  modalValue: Boolean,
  editText: {
    type: String,
    default: '编辑',
  },
  confirm: {
    type: Function,
    default: () => ({}),
  },
  cancelText: {
    type: String,
    default: '取消',
  },
  okText: {
    type: String,
    default: '确定',
  },
  dtParams: {
    type: Object,
    default: () => ({}),
  },
  showCheckbox: Boolean,
  maskZIndex: {
    type: Number,
    required: false,
  },
  contentZIndex: {
    type: Number,
    required: false,
  },
});

const emit = defineEmits(['close']);

const visible = ref(props.modalValue);

watch(() => props.modalValue, (val) => {
  visible.value = val;
});

const modalStyle = computed(() => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));
const maskStyle = computed(() => {
  if (props.maskZIndex !== undefined) {
    return {
      'z-index': props.maskZIndex,
    };
  }
  return {};
});

const close = () => {
  visible.value = false;
  emit('close');
};

const showModal = () => {
  visible.value = true;
};

const confirmDelete = () => {
  props.confirm();
  visible.value = false;
  close();
};

const cancelDelete = () => {
  visible.value = false;
  close();
};

const getDtParams = () => util.genQueryString(props.dtParams);
</script>

<template>
  <div
    v-show="visible"
    class="modal-container"
    :dt-params="getDtParams()"
    dt-eid="quickstart_group_delete"
  >
    <div
      class="mask"
      :style="maskStyle"
      @click="close"
    />
    <div
      class="modal-body modal-box-shadow"
      :style="modalStyle"
    >
      <div class="modal-header">
        <p class="title">
          {{ deleteText }}
        </p>
        <i
          class="icon-close"
          @click="close"
        >+</i>
      </div>
      <div class="modal-content">
        {{ content }}
      </div>
      <div class="modal-footer">
        <div
          :style="{ display: showCheckbox ? 'block' : 'none'}"
          class="footer-left"
        >
          <div class="check" />
          <span class="check-text">不再提示</span>
        </div>
        <div class="footer-right">
          <button
            class="modal-btn text-link"
            dt-keep-report="true"
            dt-eid="cancel_group_delete"
            @click="cancelDelete"
          >
            {{ cancelText }}
          </button>
          <button
            class="modal-btn btn-primary text-link"
            dt-keep-report="true"
            dt-eid="group_delete"
            @click="confirmDelete"
          >
            {{ okText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import './index.less';
</style>
