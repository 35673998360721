<script lang="ts">
import util from '@/utils/util';
import { defineComponent } from 'vue';
import global from '@/configs/global';

export default defineComponent({
  name: 'WidgetComp',
  components: {
  },
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
    groupIndex: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    blankOpen: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showModal: false,
      showEditModal: false,
      showContextGroupModal: false,
      showContextWidgetModal: false,
      contextPosition: null,
      editPosition: null,
      position: null,
      drag: false,
      editIndex: -1,
      widgetData: {},
    };
  },
  computed: {
    openTarget() {
      return this.blankOpen ? '_blank' : '_self';
    },
  },
  methods: {
    getUrlObj(item: any) {
      let urlObj: any = {};
      try {
        urlObj = new window.URL(item.url);
      } catch (err) {
        // catch error
      }
      return urlObj;
    },
    getSiteUrl(item: any) {
      if (!item) return '';
      const urlObj = this.getUrlObj(item);
      const url = urlObj.href || '';
      return url;
    },
    getSiteTitle(item: any) {
      if (!item) return '';
      const urlObj = this.getUrlObj(item);
      return item.title || urlObj.hostname || '';
    },
    hasBgImg(item: any) {
      if (!item) return false;
      return item.img && item.img !== global.DEFAULT_ICON_IMAGE && !/default_icon\.png$/.test(item.img);
    },
    getSiteBg(item: any) {
      let img = '';
      try {
        img = new window.URL(item.img).href;
      } catch (err) {
        // catch error
      }
      if (/\(|\)/.test(img)) {
        img = global.DEFAULT_ICON_IMAGE;
      }
      const bg = `background-image: url(${img || global.DEFAULT_ICON_IMAGE})`;
      return bg;
    },
    getSiteStyle(item: any) {
      if (this.hasBgImg(item)) {
        return this.getSiteBg(item);
      }
      return this.getSiteBgByIconColor(item).style;
    },
    // 自动取色
    getSiteBgByIconColor(item: any) {
      const title = this.getSiteTitle(item);
      const url = this.getSiteUrl(item);
      const word = util.getFirstWord(title).toUpperCase();
      let style = '';
      if (item?.iconBg) {
        style = item?.iconBg;
      } else {
        style = `background:${global.DEFAULT_ICON_COLOR}`;
        setTimeout(() => util.getIconColorByUrl([url]), 100); // 延时取色，优先主逻辑
      }
      return { style, word };
    },
    getDtParams(item: any, index: number) {
      const title = this.getSiteTitle(item);
      const url = this.getSiteUrl(item);
      const dtParams = util.genQueryString({
        position: index + 1,
        jump_url: url,
        name: title,
        group_id: this.groupIndex,
        group_name: this.groupIndex,
        group_position: this.groupIndex,
      });
      return dtParams;
    },
  },
});
</script>
<template>
  <a
    :key="index"
    dt-eid="quickstart"
    dt-imp-ignore="true"
    :target="openTarget"
    :href="getSiteUrl(widget)"
    :dt-params="getDtParams(widget, index)"
    :data-origin="getUrlObj(widget).origin"
    :class="['site-item', hasBgImg(widget) ? '' : 'no-icon']"
    :data-log="`SITES.${index + 1}`"
  >
    <span :style="getSiteStyle(widget)">{{ hasBgImg(widget) ? '' : getSiteBgByIconColor(widget).word }}</span>
    <i class="site-title">{{ getSiteTitle(widget) }}</i>
  </a>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.ghost {
  opacity: 0.5;
  cursor: pointer !important;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>
