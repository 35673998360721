<script lang="ts">
import { defineComponent } from 'vue';
import { useScale } from '@/provider/transform';

export default defineComponent({
  name: 'WidgetFull',
  props: {
    modalValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      name: '',
      showDelete: false,
      visible: false,
    };
  },
  mounted() {
    this.visible = this.modalValue;
    document.body.appendChild(this.$el);
  },
  beforeUnmount() {
    document.body.removeChild(this.$el);
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit('close');
    },
    showModal() {
      this.visible = true;
    },
    confirmDelete() {
      this.visible = false;
      this.close();
    },
    cancelDelete() {
      this.visible = false;
      this.close();
    },
  },
});
</script>
<template>
  <div
    v-show="visible"
    class="modal-container"
  >
    <div
      class="mask"
      @click="close"
    />
    <div
      class="modal-body"
    >
      <div class="modal-header">
        <p class="title">
          分组已满
        </p>
        <i
          class="icon-close"
          @click="close"
        >+</i>
      </div>
      <div class="modal-content">
        当前分组已满，请切换至其他分组添加
      </div>
      <div class="modal-footer">
        <button
          class="modal-btn"
          @click="cancelDelete"
        >
          取消
        </button>
        <button
          class="modal-btn btn-primary"
          @click="confirmDelete"
        >
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
