<template>
  <div
    class="top-tip"
  >
    <i
      class="icon-ring"
    />
    <p class="text">
      全新的新标签页上线了，支持自定义网址导航、更换个性化皮肤，快快体验吧~
    </p>
    <button
      class="btn-know"
      @click="$emit('close')"
    >
      知道了
    </button>
    <i
      class="icon-close"
      @click="$emit('close')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TopTipComp',
});
</script>

<style lang="less" scoped>
@import './index.less';
</style>

