export default {
  CONFIRM: 'CONFIRM',
  TODAY: 'TODAY',
  WEEK: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  MONTH: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ],
  CN_MONTH: [],
  DEFAULT_DATE_FORMAT: 'MM DD,YY',
  DEFAULT_YEAR_MONTH_FORMAT: 'DD,YY',
  DEFAULT_TIME_FORMAT: 'at hh:mm F',
  DEFAULT_SIMPLE_YEAR_MONTH_FORMAT: 'MM,YY',
};
