<script setup lang="ts">
import { PropType, ref, defineModel } from 'vue';
import { Option } from '@/types';

interface SelectorOption extends Option {
  children?: Option[],
}

const props = defineProps({
  options: {
    type: Array as PropType<SelectorOption[]>,
    default: () => [],
  },
  defaultValue: {
    type: String,
  },
});

const model = defineModel<string>();
const emits = defineEmits(['onChange', 'select']);
const show = ref(false);
const label = ref('');
const childOptions = ref<Option[]>([]);

const showList = () => {
  show.value = !show.value;
  childOptions.value = [];
};


const onSelect = (option: SelectorOption) => {
  childOptions.value = option.children || [];
  if (childOptions.value.length === 0) {
    onChange(option);
  }
  emits('select', option);
};
const onChange = (option: SelectorOption) => {
  if (option.value === model.value) return;
  show.value = false;
  childOptions.value = [];
  model.value = option.value;
  emits('onChange', option.value);
  label.value = option.label;
};
console.log(props.options);

defineExpose({
  showList,
});

</script>
<template>
  <div class="cascader">
    <div
      class="cascader-item"
      @click="showList"
    >
      <span class="selector-label form-item-control-label">{{ model }}</span>
      <i class="icon-arrow-bottom" />
    </div>
    <div class="cascader-menus">
      <div
        v-if="show"
        class="cascader-menu"
      >
        <div
          v-for="(option, index) in props.options"
          :key="index"
          :class="{
            'option-item':true,
            'active': model === option.value,
            'no-children': !option.children
          }"
          @click="onSelect(option)"
        >
          {{ option.label }}
        </div>
      </div>
      <div
        v-if="childOptions.length > 0"
        class="cascader-menu"
      >
        <div
          v-for="(option, index) in childOptions"
          :key="index"
          :class="{
            'option-item':true,
            'active': model === option.value,
            'no-children': true,
          }"
          @click="onChange(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './cascader.less';
</style>
