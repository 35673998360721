<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { LOAN_FORM_ITEM, LOAN_TYPE, LoanCalcRes, REPAYMENT_TYPE } from '@/types';
import FormItem from './form-item.vue';
import LoanResult from './loan-result.vue';
import { benxi, benjin, mergeRes } from '@/lib/mortgage';

const loanOptions = [
  {
    label: '商业贷款',
    value: LOAN_TYPE.commerce,
  },
  {
    label: '公积金贷款',
    value: LOAN_TYPE.funds,
  },
  {
    label: '组合贷款',
    value: LOAN_TYPE.hybrid,
  },
];

const repaymentOptions = [
  {
    label: '等额本息',
    value: REPAYMENT_TYPE.debx,
  },
  {
    label: '等额本金',
    value: REPAYMENT_TYPE.debj,
  },
];

interface FormState {
  loanType: LOAN_TYPE;
  commerceTotal: string;
  fundsTotal: string;
  loanYears: number;
  commerceRate: number;
  fundsRate: number;
  repaymentType: REPAYMENT_TYPE;
}

const formState = reactive<FormState>({
  loanType: LOAN_TYPE.commerce,
  commerceTotal: '',  // 商贷总额
  fundsTotal: '',  // 公积金贷款总额
  loanYears: 30, // 贷款年限
  commerceRate: 4.9, // 商业贷款利率
  fundsRate: 3.25, // 商业贷款利率
  repaymentType: REPAYMENT_TYPE.debx, // 还款方式
});

const isDebx = computed(() => formState.loanType === LOAN_TYPE.commerce);

const options = computed<LOAN_FORM_ITEM[]>(() => {
  const commerceOptions = [
    {
      label: '商业贷款总额',
      name: 'commerceTotal',
      unit: '万',
      placeholder: '请输入贷款总额',
    },
    {
      label: '商业贷款利率',
      name: 'commerceRate',
      unit: '%',
      placeholder: '请输入贷款利率',
      isInt: true,
    },
  ];
  const fundsOptions = [
    {
      label: '公积金贷款总额',
      name: 'fundsTotal',
      unit: '万',
      placeholder: '请输入贷款总额',
    },
    {
      label: '公积金贷款利率',
      name: 'fundsRate',
      unit: '%',
      placeholder: '请输入贷款利率',
    },
  ];
  let options = [...commerceOptions, ...fundsOptions];
  if (formState.loanType === LOAN_TYPE.commerce) {
    options = commerceOptions;
  }
  if (formState.loanType === LOAN_TYPE.funds) {
    options = fundsOptions;
  }
  return [
    {
      label: '贷款方式',
      name: 'loanType',
      component: 'selector',
      options: loanOptions,
    },
    ...options,
    {
      label: '贷款年限',
      name: 'loanYears',
      unit: '年',
      placeholder: '请选择贷款年限',
      component: 'selector',
      options: Array.from({ length: 30 }, (_, index) => {
        const number = index + 1;
        return {
          label: `${number}年`,
          value: number,
        };
      }),
    },

    {
      label: '还款方式',
      component: 'selector',
      name: 'repaymentType',
      options: repaymentOptions,
    },
  ];
});

const loadResult = ref<LoanCalcRes>({
  repaymentTypeText: repaymentOptions.find(option => option.value === formState.repaymentType)?.label || '',
  repaymentTotal: 0,
  interestTotal: 0,
  mouthData: [],
});

const showResult = ref(false);


const cal = () => {
  const {
    commerceTotal,
    fundsTotal,
    loanType,
    commerceRate,
    loanYears,
    fundsRate,
    repaymentType,
  } = formState;
  const fn = repaymentType === REPAYMENT_TYPE.debx ? benxi : benjin;
  const repaymentTypeText = repaymentOptions.find(option => option.value === repaymentType)?.label || '';
  if (!commerceTotal && !fundsTotal) {
    return;
  }
  if (loanType !== LOAN_TYPE.hybrid) {
    const rate = isDebx.value ? commerceRate : fundsRate;
    const total = isDebx.value ? commerceTotal : fundsTotal;
    const res = fn(total, loanYears, Number(rate) / 100);
    loadResult.value = {
      repaymentTotal: res.totalPrice,
      interestTotal: res.totalLixi,
      mouthData: res.mouthdataArray,
      repaymentTypeText,
    };
  } else {
    if (!commerceTotal || !fundsTotal) {
      return;
    }
    const commerceRes = fn(commerceTotal, loanYears, Number(commerceRate) / 100);
    const gjjRes = fn(fundsTotal, loanYears, Number(fundsRate) / 100);
    const res = mergeRes(commerceRes, gjjRes);
    loadResult.value = {
      repaymentTotal: res.totalPrice,
      interestTotal: res.totalLixi,
      mouthData: res.mouthdataArray,
      repaymentTypeText,
    };
  }
};

const startCalc = () => {
  cal();
  displayResult();
};

const handleSubmit = (e: Event) => {
  e.preventDefault();
  startCalc();
};

const emits = defineEmits(['display']);

const displayResult = () => {
  showResult.value = !showResult.value;
  emits('display', !showResult.value);
};

</script>
<template>
  <div>
    <form
      v-show="!showResult"
      novalidate
      class="form"
      @submit="handleSubmit"
    >
      <FormItem
        v-for="option in options"
        :key="option.name"
        v-bind="option"
        v-model:modelValue="formState[option.name as keyof FormState]"
      />
      <div class="form-item no-style">
        <button
          class="form-item-button"
        >
          开始计算
        </button>
      </div>
    </form>
    <LoanResult
      v-show="showResult"
      v-bind="loadResult"
      @back="displayResult"
    />
  </div>
</template>
<style lang="less" scoped>
@import './selector.less';
</style>
