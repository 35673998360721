import { WIDGET_COMPONENT_NAME } from '@/types';

export const GROUP_MENU_TEXTS = {
  delete: '删除分组',
  edit: '编辑分组',
};

export const WIDGET_MENU_TEXTS = {
  delete: '删除',
  edit: '编辑',
};

export const WIDGET_SIZE_TYPE = {
  '1x1': 'icon-size-1x1',
  '2x2': 'icon-size-2x2',
  '2x4': 'icon-size-2x4',
};

export const WIDGET_DESC_MAP: [keyof WIDGET_COMPONENT_NAME] = {
  todo: '一款简单好用的便签组件，快速录入待办，提高每日效率！',
  calculator: '提供科学计算器、房贷计算器、利率调整计算器',
  weather: '春有百花秋有月，夏有凉风冬有雪，心里有天气，都是人间好时节~',
  calendar: '带你领悟“时间美”，了解每日农历、宜忌、二十四节气、放假安排等',
};
