import WidgetFullModal from '@/components/modal/widget-full/index.vue';
import { createVNode, createApp } from 'vue';

let instance: any = null;

export default {
  /**
   *
   * @param options
   * @returns
   * .then参数为 left 或right
   */
  show(options: any) {
    return new Promise((resolve) => {
      function confirm() {
        options?.confirm();
        resolve(options?.confirm);
      }
      if (instance) {
        instance.destroy();
      }
      const app = createApp({
        render() {
          return createVNode(WidgetFullModal, { ...options, confirm });
        },
      });
      const container = document.createElement('div');
      document.body.appendChild(container);
      instance = app.mount(container);
      instance.destroy = function () {
        app.unmount();
        instance = null;
        document.body.removeChild(container);
      };
    });
  },
};
