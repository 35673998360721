import { exception } from '@tencent/react-error-boundary';

import { safeJSONParse, standardErrorHandler } from './util';
import { PrefUserData } from '@/types/pref';

export const enum EQbAPiKey {
  USER_DATA_KEY = 'NewTab.FavoriteTab',
};

export default class QbAPI {
  @exception(standardErrorHandler)
  public static getVersion() {
    return window.external.getVersion();
  }

  @exception(standardErrorHandler)
  public static getQUA() {
    return window.external.getQUA();
  }

  @exception(standardErrorHandler)
  public static getTGuid() {
    return window.external.getFavSyncGuid();
  }

  @exception(standardErrorHandler)
  public static getGuid() {
    return window.external.getGuid().replace(/-/g, '');
  }

  @exception(standardErrorHandler)
  public static getQ36() {
    return QbAPI.isSogou() ? '' : window.external.getQ36();
  }

  @exception(standardErrorHandler)
  public static isSogou() {
    return /PR=SE/.test(QbAPI.getQUA());
  }

  @exception(standardErrorHandler)
  public static getLoginInfo(): Promise<LoginInfo> {
    return new Promise((resolve) => {
      window.chrome.account.getLoginInfo((res: LoginInfo) => {
        resolve(res);
      });
    });
  }

  @exception(standardErrorHandler)
  public static refreshToken(qbId: string, openId: string): Promise<boolean> {
    return new Promise((resolve) => {
      window.chrome.account.refreshToken(({ qbid: qbId, openid: openId }), (success: boolean) => {
        resolve(success);
      });
    });
  }

  @exception(standardErrorHandler)
  public static async getUserData(): Promise<PrefUserData> {
    const jsonData = await QbAPI.getStringPref(EQbAPiKey.USER_DATA_KEY);

    return safeJSONParse(jsonData);
  }

  public static setScreenBg(image: string) {
    return new Promise((resolve) => {
      window.chrome.qbSkin.setScreenBg(image, (res: SystemResponse) => {
        resolve(res.succeeded);
      });
    });
  }

  @exception(standardErrorHandler)
  public static addPrefsListener(callback: (key: EQbAPiKey, value: any) => void) {
    window.chrome.prefs.onChanged.addListener(callback as (key: string, value: any) => void);
  }

  @exception(standardErrorHandler)
  public static removePrefsListener(callback: (key: EQbAPiKey, value: any) => void) {
    window.chrome.prefs.onChanged.removeListener(callback as (key: string, value: any) => void);
  }

  private static getStringPref(key: string): Promise<string> {
    return new Promise((resolve) => {
      window.chrome.prefs.getStringPref(key, (res) => {
        resolve(res);
      });
    });
  }
}

