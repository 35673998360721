<script setup lang="ts">
import { ref, defineModel } from 'vue';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
const model = defineModel<string>();
const datepicker = ref();
const locale = ref(zhCn);
const emits = defineEmits(['onChange']);
const showDate = () => {
  datepicker.value.handleOpen();
};
const onChange = (value) => {
  model.value = value;
  emits('onChange', value);
};

</script>
<template>
  <el-config-provider :locale="locale">
    <div class="date-picker">
      <div
        class="date-picker-item"
        @click.prevent.stop="showDate"
      >
        <i class="icon-date" />
      </div>
      <el-date-picker
        ref="datepicker"
        v-model="model"
        type="date"
        :clearable="false"
        placeholder="请选择日期"
        @change="onChange"
      />
    </div>
  </el-config-provider>
</template>
<style lang="less">
@import './datepicker.less';
</style>
