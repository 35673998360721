import { Widget, WIDGET_SIZE, WIDGET_SIZE_WIDTH } from '@/types';
import { genUuid } from '@/utils/util';
import  { getComponentOccupiedCells } from './grid2';

class GridManager {
  private gridRows: number;
  private gridCols: number;
  private totalCells: number;
  private components: Widget[];
  private grid: number[][];
  private componentTypeMap: {
    [key: number]: Widget['size']
  };

  constructor(components: Widget[]) {
    this.gridRows = 4;
    this.gridCols = 10;
    this.totalCells = this.gridRows * this.gridCols;
    this.components = components;
    this.grid  = Array.from({ length: 4 }, () => Array(10).fill(-1));
    this.componentTypeMap = {};
    this.initComponents(components);
  }


  public addComponent(newComponent: Widget): Widget[] {
    if (!this.canPlaceComponent(newComponent)) {
      throw new Error('Cannot place the component due to size constraints or grid limitations.');
    }

    // this.components.push({
    //   ...newComponent,
    //   id: newComponent.id || genUuid(),
    // });
    // this.grid = getComponentOccupiedCells(this.components);
    return this.sortComponents(newComponent);
  }

  private initComponents(components: Widget[]) {
    this.components = components.map((component, idx) => {
      this.componentTypeMap[idx] = component.size || WIDGET_SIZE['1x1'];
      return {
        ...component,
        id: component.id || genUuid(),
      };
    });
    this.grid = getComponentOccupiedCells(this.components);
  }

  private parseSize(size: string | undefined): [number, number] {
    if (!size) return [1, 1];
    const [rows, cols] = size.split('x').map(Number);
    return [rows, cols];
  }

  private canPlaceComponent(newComponent: Widget): boolean {
    const [newRows, newCols] = this.parseSize(newComponent.size);
    if (newRows > this.gridRows || newCols > this.gridCols) return false;
    if (newRows > 1 && newCols > 1 && newRows + newCols > this.gridRows + this.gridCols - 2) return false;

    let occupiedCells = 0;
    for (const component of this.components) {
      const [rows, cols] = this.parseSize(component.size);
      occupiedCells += rows * cols;
    }
    const newComponentCells = newRows * newCols;
    return occupiedCells + newComponentCells <= this.totalCells;
  }


  private sortComponents(component: Widget): Widget[] {
    console.log(this.grid);
    const flatGrid = this.grid.flat();
    const fillGridLen = flatGrid.filter(grid => grid !== -1).length;
    const [rows, cols] = this.parseSize(component.size);
    if (fillGridLen < 30 - cols) {
      this.components.push(component);
      return this.components;
    } if (fillGridLen >= 30 - cols) {
      const thirdGrid = this.grid[2];
      const duplicates = this.findDuplicates(thirdGrid);
      if (duplicates.length) {
        const firstDuplicate = duplicates[0];
        const beforeItems = this.findItemsBeforeDuplicates(thirdGrid, WIDGET_SIZE_WIDTH[component.size]);
        const components = this.components.slice(0);
        const result = this.insertAndMoveElements(components, firstDuplicate, component);
        return result;
      }
      const maxValue = Math.max(...thirdGrid);
      const components = this.components.slice(0);
      const result = this.insertAndMoveElements(components, maxValue, component);
      return result;
    }
    return this.components;
  }

  private insertAndMoveElements(arr, N, component) {
    // 取出第N项前的K个元素
    const K = WIDGET_SIZE_WIDTH[component.size];
    const elementsToMove = arr.slice(N - K, N);

    // 在第N项前插入新的K个元素
    const part1 = arr.slice(0, N - K);
    const part2 = arr.slice(N);
    arr = part1.concat([component]).concat(part2);

    // 将取出的K个元素插入到第N项后面
    const part3 = arr.slice(0, N);
    const part4 = arr.slice(N);
    arr = part3.concat(elementsToMove).concat(part4);

    return arr;
  }

  private findItemsBeforeDuplicates(arr: number[], n: number): number[] {
    const itemsBeforeDuplicates: number[] = [];

    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i] === arr[i + 1] && arr[i] !== -1) {
        for (let j = n; j > 0; j--) {
          if (i >= j) {
            itemsBeforeDuplicates.push(arr[i - j]);
          }
        }
      }
    }

    return itemsBeforeDuplicates;
  }

  private findDuplicates(arr: number[]): number[] {
    const duplicates: number[] = [];
    const count: { [key: number]: number } = {};

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== -1) {
        if (count[arr[i]] !== undefined) {
          count[arr[i]]++;
          if (count[arr[i]] === 2) {
            duplicates.push(arr[i]);
          }
        } else {
          count[arr[i]] = 1;
        }
      }
    }

    return duplicates;
  }
}

export default GridManager;
