<script setup lang="ts">
import { PropType, ref } from 'vue';
import ModalContainer from '@/components/modal/modal/index.vue';
import { Calendar, CalendarDay } from './components/calendar/index';
import { Widget, WIDGET_MODE, WIDGET_SIZE } from '@/types';
import { Solar } from 'lunar-typescript';
const props = defineProps({
  showName: {
    type: Boolean,
    default: true,
  },
  mode: {
    type: String,
    default: WIDGET_MODE.common,
    required: false,
  },
  size: {
    type: String,
    default: WIDGET_SIZE['2x2'],
    required: false,
  },
  widget: {
    type: Object as PropType<Widget>,
    default: () => ({
      size: WIDGET_SIZE['2x2'],
    }),
  },
});
const showModal = ref(false);

const year = ref(new Date().getFullYear());
const month = ref(new Date().getMonth() + 1);
const day = ref(new Date().getDate());

const solar = ref(Solar.fromYmd(year.value, month.value, day.value));
const lunar = ref(solar.value.getLunar());
const currentYearMonth = ref({
  year,
  month,
  day,
});

const close = () => {
  showModal.value = false;
};

const handleCalendarClick = () => {
  if (props.mode === WIDGET_MODE.preview) return;
  showModal.value = true;
};

const onChange = (date: any) => {
  currentYearMonth.value = {
    year: new Date(date).getFullYear(),
    month: new Date(date).getMonth() + 1,
    day: new Date(date).getDate(),
  };
};

const getDate = () => `${lunar?.value?.getMonthInChinese()}月${lunar?.value?.getDayInChinese()} 周${solar?.value?.getWeekInChinese()} ${lunar?.value?.getJieQi()}`;
</script>
<template>
  <div class="calendar-widget h-full w-full">
    <div
      class="h-full w-full contents"
      :style="{
        justifyContent: props?.widget?.size === WIDGET_SIZE['2x4'] ? 'space-between' : 'center',
      }"
      @click="handleCalendarClick"
    >
      <div class="calendar-widget-wrapper">
        <div class="year">
          <span>{{ year }}年{{ month }}月</span>
        </div>
        <div class="date">
          {{ day }}
        </div>
        <div class="lunar-date">
          {{ getDate() }}
        </div>
      </div>
      <div
        v-if="props?.widget?.size === WIDGET_SIZE['2x4']"
        class="calendar-widget-calendar-wrapper"
        @click="handleCalendarClick"
      >
        <div
          class="cover"
          @click="handleCalendarClick"
        />
        <div class="small-title">
          {{ `${lunar?.getMonthInChinese()}月` }}
        </div>
        <Calendar
          :showSimpleAction="false"
          :showAction="false"
          :changeYearFast="true"
          :cancelEvent="true"
          @change="onChange"
        />
      </div>
    </div>
    <p
      v-if="props.showName"
      class="item-title widget-item-name"
    >
      日历
    </p>
    <ModalContainer
      v-if="showModal"
      class="calendar-modal-container"
      :preventContextMenu="true"
      @close="close"
    >
      <div class="calendar-modal-content h-full">
        <div class="modal-left">
          <CalendarDay :currentYearMonth="currentYearMonth" />
        </div>
        <div class="modal-right">
          <Calendar
            :showSimpleAction="true"
            :showAction="false"
            :changeYearFast="true"
            @change="onChange"
          />
        </div>
      </div>
    </ModalContainer>
  </div>
</template>
<style lang="less">
@import "./components/calendar/index.less";
@import "./index.less";
</style>
<style lang="less">
.calendar-modal-container {
  &.modal-container {
    .modal-body {
      padding: 0;
      .modal-content {
        height: 100%;
        max-height: none !important;
        overflow-y: visible;
      }
    }
  }
}
</style>
