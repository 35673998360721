<script setup lang="ts">
import { ref } from 'vue';
import LoanForm from './loan-form.vue';
import LoanRate from './loan-rate.vue';
import Tabs from '../tabs/index.vue';
const tabs = [
  {
    label: '住房贷款',
    value: 'loan',

  }, {
    label: '利率调整计算',
    value: 'rate',
  },
];

const activeTab = ref('rate');
const showTab = ref(true);
const display = (show: boolean) => {
  showTab.value = show;
};
const onTabClick = (val: string) => {
  activeTab.value = val;
};
</script>
<template>
  <div class="house-loan-panel">
    <Tabs
      v-if="showTab"
      v-model="activeTab"
      :options="tabs"
      @change="onTabClick"
    />
    <LoanForm
      v-show="activeTab === 'loan'"
      @display="display"
    />
    <LoanRate
      v-show="activeTab !== 'loan'"
      @display="display"
    />
  </div>
</template>
<style lang="less">
@import './index.less';
</style>
