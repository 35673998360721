<script setup lang="ts">
import { computed, nextTick, onMounted, PropType, ref } from 'vue';
import ModalContainer from '@/components/modal/modal/index.vue';
import { v4 as uuidv4 } from 'uuid';
import { Position, Widget, WIDGET_COMPONENT_NAME, WIDGET_MODE } from '@/types';
import deleteModal from '@/plugins/delete-modal';
import AssetHelper from '@/utils/asset-helper';
import lcLog from '@/lib/lclog';

interface TodoItem {
  id: string;
  content: string;
  finished: boolean;
  updateTime: number;
}

interface TodoList {
  id: string;
  name: string;
  updateTime: number;
  children: TodoItem[];
  show: boolean;
}

const props = defineProps({
  showName: {
    type: Boolean,
    default: true,
  },
  mode: {
    type: String,
    default: WIDGET_MODE.common,
    required: false,
  },
  widget: {
    type: Object as PropType<Widget>,
    default: () => ({}),
  },
});


const previewTodoList = ref<TodoList[]>([
  {
    id: uuidv4(),
    name: '极简便签',
    updateTime: Date.now(),
    children: [
      {
        id: uuidv4(),
        content: '今日事项',
        finished: false,
        updateTime: Date.now(),
      },
    ],
    show: true,
  },
]);

const todoListModal = ref<HTMLAreaElement>();
const textarea = ref<HTMLAreaElement[]>([]);
const input = ref<HTMLInputElement>();
const todoList = ref<TodoList[]>([]);
const showModal = ref(false);
const showSmallTodo = ref(true);
const inputVal = ref('');

const handleInput = (e: Event) => {
  const textArea = e.target as HTMLAreaElement;
  textArea.style.height = 'auto';
  textArea.style.height = textArea.scrollHeight > 112 ? '112px' : `${textArea.scrollHeight}px`;
};

const list = computed(() => todoList?.value?.children || []);
const isPreviewMode = computed(() => props.mode === WIDGET_MODE.preview);
const editTodoCompIdx = ref(-1);
onMounted(() => {
  if (isPreviewMode.value) {
    todoList.value = previewTodoList.value;
    return;
  }

  const { todoList: currentTodoList = [] } = AssetHelper.getUserData();
  const compIdx = currentTodoList ? currentTodoList.findIndex(item => item.id === props.widget.id) : -1;
  editTodoCompIdx.value = compIdx;
  if (editTodoCompIdx.value < 0) {
    todoList.value = [];
    editTodoCompIdx.value = 0;
    showSmallTodo.value = true;
  } else {
    todoList.value = currentTodoList[editTodoCompIdx.value];
    showSmallTodo.value = todoList.value.show;
  }
});

const setUserTodoData = async () => {
  try {
    await AssetHelper.updateComponentData(WIDGET_COMPONENT_NAME.todo, todoList.value.id, todoList.value);
  } catch (e) {
    lcLog.error('setUserTodoData', e);
  }
};

const addTodo = () => {
  const { value } = inputVal;
  if (!value.trim()) return;
  todoList.value?.children.push({
    id: `todo_${uuidv4()}`,
    content: value,
    finished: false,
    updateTime: new Date().getTime(),
  });
  setUserTodoData();
  inputVal.value = '';
  nextTick(() => {
    const target = textarea?.value?.[textarea?.value?.length - 1];
    target.style.height = 'auto';
    target.style.height = target.scrollHeight > 112 ? '112px' : `${target.scrollHeight}px`;
  });
};

const handleChange = (todo: TodoItem) => {
  todo.content = todo.content;
  setUserTodoData();
};

const handleCheck = (todo: TodoItem) => {
  todo.finished = !todo.finished;
  setUserTodoData();
};

// const handleClick = (todo: TodoItem, e: Event) => {
//   if (!todo.finished) {
//     (e?.target as HTMLElement)?.removeAttribute('readonly');
//   }
// };
// const handleKeydown = (e: Event) => {
//   if ((e.code.toLowerCase()) === 'enter') {
//     addTodo(e);
//   }
// };

const handleDeleteKeydown = (index, e: Event) => {
  if ((e.code.toLowerCase()) === 'backspace' && e.target.value === '') {
    onDeleteClick(index);
  }
};
const handleBlur = (e: Event) => {
  (e?.target as HTMLElement).setAttribute('readonly', 'readonly');
};

const close = () => {
  showModal.value = false;
};

const handleTodoClick = () => {
  if (isPreviewMode.value) return;
  showModal.value = true;
  nextTick(() => {
    textarea?.value.forEach((target) => {
      target.style.height = 'auto';
      target.style.height = target.scrollHeight > 112 ? '112px' : `${target.scrollHeight}px`;
    });
  });
};

const onDeleteClick = (index: number) => {
  todoList.value.children.splice(index, 1);
  setUserTodoData();
};

const onClearConfirm = () => {
  todoList.value.children = [];
  setUserTodoData();
};

const onClearClick = (e: { preventDefault: () => void; target: { getBoundingClientRect: () => any; }; }) => {
  e.preventDefault();
  deleteModal.show({
    deleteText: '清空内容',
    modalValue: true,
    content: '确定清空所有内容吗？',
    maskZIndex: 1002,
    dtParams: {},
    confirm: () => {
      onClearConfirm();
    },
  });
};

const onDisplayClick = () => {
  if (isPreviewMode.value) return;
  showSmallTodo.value = !showSmallTodo.value;
  todoList.value.show = showSmallTodo.value;
  setUserTodoData();
};

</script>
<template>
  <div class="todo-widget h-full w-full">
    <div
      class="h-full w-full contents"
      @click="handleTodoClick"
    >
      <div class="header">
        <span>极简便签</span>
        <i
          :class="{
            'icon-eyes': true,
            'icon-eyes-close': !showSmallTodo,
          }"
          @click.prevent.stop="onDisplayClick"
        />
      </div>
      <div
        v-if="showSmallTodo"
        class="todo-list"
      >
        <div
          v-for="todo in list.slice(0, 3)"
          :key="todo.id"
          :class="{
            'todo-item': true,
            finished: todo.finished,
          }"
        >
          <img
            v-if="todo.finished"
            class="checked-icon"
            src="https://st.tencent-cloud.com/jax-static/newtab/new-version/d3483d3c0e5a25b5092134d902853409.png"
            alt=""
          >
          <div
            v-else
            class="todo-item-checkbox"
          />

          <span class="todo-item-content">{{ todo.content }}</span>
        </div>
        <p
          v-if="list.length === 0"
          class="todo-item-content no-todo-text"
        >
          暂无事项
        </p>
      </div>
      <div
        v-else
        class="todo-list"
      >
        <div class="skeleton">
          <div class="skeleton-paragraph" />
          <div class="skeleton-paragraph" />
          <div class="skeleton-paragraph skeleton-title" />
        </div>
      </div>
    </div>
    <p
      v-if="props.showName"
      class="item-title widget-item-name"
    >
      极简便签
    </p>
    <ModalContainer
      v-if="showModal"
      class="todo-modal-container"
      :preventContextMenu="true"
      @close="close"
    >
      <div class="todo-modal-content h-full">
        <span
          v-if="todoList?.children?.length > 0"
          class="clear-box"
          @click="onClearClick"
        >
          <i class="icon-clear" />
          清空
        </span>
        <h2 class="todo-title">
          极简便签
        </h2>
        <div
          ref="todoListModal"
          class="todo-modal-list"
        >
          <div
            v-for="(todo, index) in list"
            :key="todo.id"
            :class="{
              'todo-modal-item': true,
              'todo-modal-real-item': true,
              finished: todo.finished,
            }"
          >
            <div
              class="checkbox-item"
              @click="handleCheck(todo)"
            >
              <div
                v-if="todo.finished"
                class="checked"
              >
                <img
                  class="checked-icon"
                  src="https://st.tencent-cloud.com/jax-static/newtab/new-version/d3483d3c0e5a25b5092134d902853409.png"
                  alt=""
                >
              </div>
              <div
                v-else
                class="unchecked"
              />
            </div>
            <div class="todo-modal-item-content">
              <textarea
                ref="textarea"
                v-model="todo.content"
                rows="1"
                class="todo-input"
                maxlength="200"
                placeholder="输入新事项"
                @input="handleInput"
                @change="handleChange(todo)"
                @keydown="(e) => handleDeleteKeydown(index, e)"
              />
            </div>
            <i
              class="icon-delete"
              @click="onDeleteClick(index)"
            />
          </div>
        </div>
        <div class="add-todo-input">
          <i class="icon icon-todo-input" />
          <div class="todo-modal-item-content">
            <input
              ref="input"
              v-model="inputVal"
              class="todo-input"
              maxlength="200"
              placeholder="输入新事项"
              @change="addTodo"
            >
            <button
              class="todo-btn"
              :disabled="!inputVal.trim()"
              @click="addTodo"
            >
              添加
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.todo-modal-container {
  &.modal-container {
    .modal-body {

      .modal-content {
        height: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
