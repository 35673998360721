<script lang="ts">
import util from '@/utils/util';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Operation } from '@/types';

interface Position {
  left: string,
  top: string;
}

export default defineComponent({
  name: 'EditWidget',
  props: {
    position: {
      type: Object as PropType<Position> | null,
      default: () => ({}),
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
    widgetData: {
      type: Object,
      default: () => ({}),
    },
    reportParams: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'confirm'],
  data() {
    return {
      url: '',
      title: '',
    };
  },
  computed: {
    modalStyle() {
      return {
        ...this.position,
      };
    },
    disabled() {
      const { url, title = '' } = this;
      const isValid = util.isUrl(url) || util.isDomain(url);
      return !title.trim() || !isValid;
    },
    dtExposureParams() {
      const { url, title } = this.widgetData;
      return util.genQueryString({
        edit_add: url ? Operation.EDIT : Operation.ADD,
        ...this.reportParams,
        jump_url: this.url || url,
        name: title || this.title,
      });
    },
  },
  mounted() {
    if (this.widgetData) {
      this.url =  this.widgetData.url;
      this.title = this.widgetData.title;
    }
    document.body.appendChild(this.$el);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      const { url, title } = this;
      if (!url || !title) {
        return;
      }
      this.$emit('confirm', {
        title: this.title,
        url: this.url,
      });
      this.close();
    },
    cancel() {
      this.close();
    },
  },
});
</script>
<template>
  <div class="modal-container">
    <div
      class="mask"
      :style="{ zIndex: zIndex }"
      @click="close"
    />
    <div
      class="modal-body modal-box-shadow"
      :dt-params="dtExposureParams"
      dt-eid="quickstart_edit_add_panel"
      :style="modalStyle"
    >
      <div class="modal-content">
        <div class="form-item">
          <input
            v-model="title"
            class="form-input"
            placeholder="请输入网站名称 "
            type="text"
            @keyup.enter="confirm"
          >
        </div>
        <div class="form-item">
          <input
            v-model="url"
            class="form-input"
            placeholder="请输入网站地址"
            type="text"
            @keyup.enter="confirm"
          >
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="modal-btn btn-primary"
          dt-keep-report="true"
          dt-imp-ignore="true"
          dt-eid="confirm"
          :dt-params="dtExposureParams"
          :disabled="disabled"
          @click="confirm"
        >
          确定
        </button>
        <button
          class="modal-btn"
          dt-eid="cancel"
          dt-imp-ignore="true"
          dt-keep-report="true"
          :dt-params="dtExposureParams"
          @click="cancel"
        >
          取消
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
  @import './index.less';
</style>
