<script setup lang="ts">
import { onMounted, onBeforeUnmount, PropType, ref } from 'vue';
import { ToastType } from './type';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  type: {
    type: String as PropType<ToastType>,
    default: 'success',
  },
  duration: {
    type: Number,
    default: 3000,
  },
});

const emit = defineEmits(['close']);
const timer = ref();
onMounted(() => {
  timer.value = window.setTimeout(() => {
    emit('close', props.id);
  }, props.duration);
});

onBeforeUnmount(() => {
  clearTimeout(timer.value);
});
</script>

<template>
  <div
    class="toast"
    :style="{ animationDuration: `${duration}ms` }"
  >
    <svg
      v-if="type === 'success'"
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      width="24px"
      height="24px"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="#4caf50"
      />
      <path
        d="M6 12l4 4 8-8"
        stroke="white"
        stroke-width="2"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-else-if="type === 'error'"
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      width="24px"
      height="24px"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="#f44336"
      />
      <path
        d="M8 8l8 8M8 16l8-8"
        stroke="white"
        stroke-width="2"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-else-if="type === 'info'"
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      width="24px"
      height="24px"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="#2196f3"
      />
      <rect
        x="11"
        y="7"
        width="3"
        height="3"
        fill="white"
      />
      <rect
        x="11"
        y="11"
        width="3"
        height="8"
        fill="white"
      />
    </svg>
    <svg
      v-else-if="type === 'warning'"
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      width="24px"
      height="24px"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="#ff9800"
      />
      <rect
        x="11"
        y="7"
        width="3"
        height="3"
        fill="white"
      />
      <rect
        x="11"
        y="11"
        width="3"
        height="8"
        fill="white"
      />
    </svg>
    <span>{{ message }}</span>
  </div>
</template>

<style lang="less" scoped>
@import './toast-box.less';
</style>
