<template>
  <div class="navigation-bar">
    <div class="navigation">
      <div
        v-for="(nav, index) in 2"
        :key="index"
        class="cycle"
        :class="{ active: activeIndex === index }"
        @click="onNavClick(index)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavigationComp',
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ['navClick'],
  setup(props, { emit }) {
    const onNavClick = (index) => {
      emit('navClick', index);
    };
    return {
      onNavClick,
    };
  },
});
</script>
<style lang="less" scoped>
.navigation-bar {
  position: fixed;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  z-index: 10;
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 8px 0;
    position: relative;
    .cycle {
      width: 12px;
      height: 12px;
      margin: 1px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.2);
      }
      &.active {
        &::after {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
