<script setup lang="ts">
import { defineModel, PropType } from 'vue';
import { Option } from '@/types';

const props = defineProps({
  options: {
    type: Array as PropType<Option[]>,
    default: () => [],
  },
});

const model = defineModel();
const emits = defineEmits(['change']);
const onTabClick = (tab: Option) => {
  model.value = tab.value;
  emits('change', tab.value);
};
</script>
<template>
  <div
    class="tabs"
  >
    <div
      v-for="tab in props.options"
      :key="tab.value"
      :class="{
        tab: true,
        active: tab.value === model
      }"
      @click="onTabClick(tab)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
