import { Widget } from '@/types';

 type Cell = {
   row: number;
   col: number;
 };

export function getComponentOccupiedCells(components: Widget[]): (number | null)[][] {
  const grid: (number | null)[][] = Array.from({ length: 4 }, () => Array(10).fill(-1));

  const sizeToDimensions = {
    '1x1': { rows: 1, cols: 1 },
    '2x2': { rows: 2, cols: 2 },
    '2x4': { rows: 2, cols: 4 },
  };

  function findNextAvailableCell(rows: number, cols: number): Cell | null {
    for (let row = 0; row < 4; row++) {
      for (let col = 0; col < 10; col++) {
        if (canPlaceComponent(row, col, rows, cols)) {
          return { row, col };
        }
      }
    }
    return null;
  }

  function canPlaceComponent(startRow: number, startCol: number, rows: number, cols: number): boolean {
    if (startRow + rows > 4 || startCol + cols > 10) {
      return false;
    }
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        if (grid[startRow + r][startCol + c] !== -1) {
          return false;
        }
      }
    }
    return true;
  }

  function markCellsAsOccupied(startCell: Cell, rows: number, cols: number, index: number) {
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        grid[startCell.row + r][startCell.col + c] = index;
      }
    }
  }

  components.forEach((component, index) => {
    const size = component.size || '1x1';
    const { rows, cols } = sizeToDimensions[size];

    const startCell = findNextAvailableCell(rows, cols);
    if (startCell) {
      markCellsAsOccupied(startCell, rows, cols, index);
    }
  });

  return grid;
}
