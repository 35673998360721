<script setup lang="ts">
import keypads from '../../constant/keypads';
const props = defineProps({
  handleClick: Function,
});
const handleClick = (event, type) => {
  props.handleClick(event, type);
};
</script>
<template>
  <div class="keypads-panel">
    <div
      v-for="(keys, index) in keypads['scientific']"
      :key="index"
      class="keypad-row"
    >
      <button
        v-for="key in keys"
        :id="key.id"
        :key="key.id"
        :class="['key', key.type]"
        @click="(event) => handleClick(event, key.type)"
      >
        <span
          class="key-label"
          v-html="key.label"
        />
      </button>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './keypads.less';
</style>
