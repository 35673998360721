import CN from './cn';
import EN from './en';

export type LanguageEntityType = {
  CONFIRM: string;
  TODAY: string;
  WEEK: string[];
  MONTH: string[];
  CN_MONTH: string[];
  DEFAULT_DATE_FORMAT: string;
  DEFAULT_TIME_FORMAT: string;
  DEFAULT_SIMPLE_YEAR_MONTH_FORMAT: string;
};

export const LanguageTypes = ['CN', 'EN'] as const;

export type LanguageType = typeof LanguageTypes[number];

export default {
  CN,
  EN,
};
