<script lang="ts">
import xssFilters from 'xss-filters';
import { defineComponent } from 'vue';
import EditWidgetModal from '@/components/modal/edit-widget/index.vue';
import SiteList from '@/components/site-list/index.vue';
import WidgetMarket from '@/components/widget-market/index.vue';
import util from '@/utils/util';
import global from '@/configs/global';

interface Position {
  left: string;
  top: string;
  zIndex?: number;
}

export default defineComponent({
  name: 'CreateWidget',
  components: {
    EditWidgetModal,
    SiteList,
    WidgetMarket,
  },
  props: {
    addedSites: {
      type: Array,
      default: () => ([]),
    },
    reportParams: {
      type: Object,
      default: () => ({}),
    },
    scalable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close', 'add', 'delete'],
  // setup(props) {
  //   const nodeRef = useDprChanged(!!props.scalable);
  //   return {
  //     nodeRef,
  //   };
  // },
  data() {
    return {
      groups: ['全部'],
      activeIndex: 0,
      allSites: [],
      sites: [],
      icon: '',
      name: '',
      position: {} as Position,
      showModal: false,
      timer: null,
      currentPage: 1,
      pageSize: 30,
      pageSites: [],
      showFullTip: false,
      defaultImg: global.DEFAULT_ICON_IMAGE,
      searchWord: '',
      tabs: [
        {
          label: '网址导航',
          id: 'WZDH',
        },
        {
          label: '小组件',
          id: 'XZJ',
          eid: 'add_components_tab',
        },
      ],
      activeTab: 'WZDH',
    };
  },
  computed: {
    showWidgetMarket() {
      return this.activeTab === 'XZJ';
    },
  },
  created() {
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  beforeUnmount() {
    document.body.removeChild(this.$el);
  },
  methods: {
    onTabClick(id) {
      this.activeTab = id;
      if (id === 'XZJ') {
        window.localStorage.setItem('show-create-widget-modal-red-point', 'false');
      }
    },
    close() {
      this.$emit('close');
    },
    confirm() {
      this.close();
    },
    cancel() {
      this.close();
    },
    add(e) {
      e.preventDefault();
      const rect = e.target.getBoundingClientRect();
      (this.position as Position | null) = {
        top: `${rect.top + rect.height + 12}px`,
        left: `${rect.left}px`,
        zIndex: 1003,
      };
      this.showModal = true;
    },
    addSite(site) {
      this.$emit('add', site);
    },
    deleteSite(index: number) {
      this.$emit('delete', index);
    },
    async confirmCustomAdd({ url, title }: any) {
      const isValid = util.isUrl(url) || util.isDomain(url);
      if (!isValid) {
        return;
      }
      let realUrl = url;

      // 补充协议
      if (util.isDomain(url)) {
        realUrl = `http://${url}`;
      }

      let data = {};
      try {
        // url = (new RegExp(".*?script[^>]*?.*?(<\/.*?script.*?>)*", "ig").test(url)) ? null : url;
        realUrl = xssFilters.inDoubleQuotedAttr(realUrl);
        const res = await util.getIconAndTitleByUrl([realUrl]);
        if (res[realUrl]) {
          data = res[realUrl];
        }
      } catch (err) {
        console.error(`get ${url} icon and title error`, err);
      }

      const obj = {
        url: realUrl,
        title: xssFilters.inHTMLData(util.encodeHTML(title) || data.title || util.parseUrl(realUrl).host || ''),
      };

      const img = data.thumbnail || '';

      if (img) {
        obj.img = img;
      } else {
        util.getIconColorByUrl([obj.url]);
      }
      this.$emit('add', obj);
    },
    addWidget(data) {
      this.$emit('add', data);
    },
    showRedPoint(id) {
      const redPoint = window.localStorage.getItem('show-create-widget-modal-red-point');
      const show = redPoint !== 'false' && id === 'XZJ';
      let redPointCls = '';
      if (show) {
        redPointCls = 'red-point';
      }
      return redPointCls;
    },
  },
});
</script>
<template>
  <div
    class="modal-container"
    dt-keep-report="true"
    dt-eid="quickstart_add_panel"
    @contextmenu.prevent.stop="() => {}"
  >
    <div
      class="mask"
      @click="close"
    />
    <EditWidgetModal
      v-if="showModal"
      :reportParams="reportParams"
      :zIndex="1003"
      :position="position"
      @confirm="confirmCustomAdd"
      @close="showModal = false"
    />
    <div
      class="modal-body modal-box-shadow"
    >
      <div
        class="icon-close"
        dt-keep-report="true"
        dt-imp-ignore="true"
        dt-eid="close_add_panel"
        @click="close"
      />
      <div class="modal-header">
        <div class="tabs">
          <div
            v-for="tab in tabs"
            :key="tab.id"
            :class="['tab', activeTab === tab.id ? 'active' : '', showRedPoint(tab.id)]"
            :dt-eid="`${tab.eid || ''}`"
            @click="onTabClick(tab.id)"
          >
            {{ tab.label }}
          </div>
        </div>
        <button
          v-if="!showWidgetMarket"
          class="btn-custom hover-state"
          dt-eid="custom_add"
          dt-imp-ignore="true"
          @click="add"
        >
          自定义添加
        </button>
      </div>
      <div class="modal-content">
        <div v-show="!showWidgetMarket">
          <SiteList
            :addedSites="addedSites"
            @add="addSite"
            @delete="deleteSite"
          />
        </div>
        <div v-show="showWidgetMarket">
          <WidgetMarket
            :addedWidgets="addedSites"
            @add="addWidget"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
