<script setup lang="ts">
import Keypads from './keypads.vue';
import Display from './display.vue';
import { ref, getCurrentInstance, ComponentInternalInstance } from 'vue';
import Decimal from '../../util/decimal-custom';
import { toUpperCamelCase } from '../../util';
import { evaluate } from '../../util/decimal-evaluate';

evaluate.extend(Decimal);

const displayValue = ref('0');
const history = ref('');
const currentOutput = ref(null);
const currentOperation = ref(null);
const resetDisplayValueOnNextKeyPress = ref(true);
const trigUnit = ref('deg');
const memory = ref(null);
const instance = (getCurrentInstance() as ComponentInternalInstance);

const handleNumberKey = (key: any) => {
  if (displayValue.value.length >= 32) return;
  const number = key.textContent;
  history.value += number;
  let displayValueTemp = resetDisplayValueOnNextKeyPress.value ? '' : displayValue.value;

  if (key.id === 'decimal' && !displayValueTemp.includes('.')) {
    displayValueTemp = displayValueTemp ? `${displayValueTemp}.` : '0.';
  } else if (key.id !== 'decimal') {
    displayValueTemp += number;
  }

  displayValue.value = displayValueTemp.replace(/^0+(?!\.)/, '');
  resetDisplayValueOnNextKeyPress.value = false;
};

const handleConstantKey = ({ id: constant }) => {
  displayValue.value = Decimal[constant].toString();
  resetDisplayValueOnNextKeyPress.value = true;
};

const handleBinaryOperationKey = (key) => {
  const operation = key.id;
  if (!currentOperation.value) {
    history.value += key.textContent;
  } else {
    history.value = history.value.substring(0, history.value.length - 1) + key.textContent;
  }
  if (currentOperation.value) {
    handleEqualsKey();
  }

  currentOperation.value = operation;
  currentOutput.value = displayValue.value;
  resetDisplayValueOnNextKeyPress.value = true;
};

const handleUnaryOperationKey = (key) => {
  const operation = key.id;
  let operand = new Decimal(displayValue.value);

  if (/^(sin|cos|tan)$/.test(operation) && trigUnit.value === 'deg') {
    const PI = Decimal.acos(-1);
    operand = operand.times(new Decimal(PI).dividedBy(180));
  }

  const output = operand[operation]();

  displayValue.value = output.toString();
  resetDisplayValueOnNextKeyPress.value = true;
};

const handleEqualsKey = () => {
  if (!currentOperation.value) {
    return;
  }
  const firstOperand = new Decimal(currentOutput.value);
  const secondOperand = displayValue.value;
  const output = firstOperand[currentOperation.value](secondOperand);

  currentOperation.value = null;
  currentOutput.value = output;
  displayValue.value = output.toString();
  resetDisplayValueOnNextKeyPress.value = true;
};

const handleClearKey = (key) => {
  if (currentOutput.value === null && displayValue.value === '0') {
    history.value = '';
  }
  currentOperation.value = null;
  currentOutput.value = null;
  displayValue.value = '0';
  resetDisplayValueOnNextKeyPress.value = true;
};

const handleFunctionKey = ({ id: functionName }) => {
  switch (functionName) {
    case 'trigUnit':
      trigUnit.value = trigUnit.value === 'deg' ? 'rad' : 'deg';
      break;
    case 'memoryAdd':
      memory.value = memory.value
        ? memory.value.plus(displayValue.value)
        : new Decimal(displayValue.value);
      break;
    case 'memorySubtract':
      memory.value = memory.value ? memory.value.minus(displayValue.value) : null;
      break;
    case 'memoryClear':
      memory.value = null;
      break;
    case 'random':
      displayValue.value = new Decimal(Math.random()).toString();
      resetDisplayValueOnNextKeyPress.value = true;
      break;
  }
};
defineExpose({
  handleNumberKey,
  handleConstantKey,
  handleBinaryOperationKey,
  handleUnaryOperationKey,
  handleEqualsKey,
  handleClearKey,
  handleFunctionKey,
});

const handleClick = (event: Event, type: string) => {
  // const handler = camelCase(`handle-${type}-Key`);
  // this[handler].call(this, event.currentTarget);
  const functionName = toUpperCamelCase(`${type}_key`);
  const func = `handle${functionName}`;
  instance.exposed[func](event.currentTarget);
};


</script>
<template>
  <div class="calculator-component">
    <Display
      :history="history"
      :value="displayValue"
    />
    <Keypads :handleClick="handleClick" />
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
