import lcLog, { doReport } from '@/lib/lclog';
import util, { safeJSONParse } from './util';
import global from '@/configs/global';
import { getFirstComeIn } from './local-storage';
const USER_DATA_KEY = 'NewTab.FavoriteTab';
const SKIN_DETAIL_KEY = 'Skin.Detail';
let userData = null;
// 注册USER_DATA_KEY
try {
  window.chrome.prefs.register(USER_DATA_KEY);

  listenSkinChanged();
} catch (error) {

}

// 更新皮肤模糊度
function listenSkinChanged() {
  window.chrome.prefs.register(SKIN_DETAIL_KEY);
  const handleSkinChanged = (value: string) => {
    const { skins } = safeJSONParse(value, {});
    const skinConfig = skins?.[0];
    const blurRadius = skinConfig?.local_skin ? skinConfig?.local_skin.blur_radius : skinConfig?.blur_radius;
    if (!isNaN(blurRadius as number) && blurRadius) {
      document.documentElement.style.setProperty('--bg-filter', `blur(${blurRadius}px)`);
    } else {
      document.documentElement.style.setProperty('--bg-filter', 'none');
    }

    // 默认场景
    if (!skins?.[0] || skins[0].id === '1') {
      document.documentElement.setAttribute('data-qblight', 'false');
    }
  };

  window.chrome.prefs.getStringPref(SKIN_DETAIL_KEY, handleSkinChanged);
  util.onPrefsChanged((key: string, value: string) => {
    if (key = SKIN_DETAIL_KEY) {
      handleSkinChanged(value);
    }
  });
}

/**
 * 读取用户数据
 */
async function getUserData() {
  const value = await util.getPrefs(USER_DATA_KEY);
  if (value && value !== 'null') {
    try {
      userData = JSON.parse(value);
      if (!userData?.list?.length) {
        userData.list = [];
      }
    } catch (err) {
      console.error('parse getPrefs result error', err);
    }
  } else {
    userData = global.GUEST_DATA;
  }
  doReport({
    action: 'getUserData',
    data: userData,
  });
  return userData;
}

/**
 * 写入用户数据
 */
export function setUserData() {
  util.setPrefs(USER_DATA_KEY, JSON.stringify(userData));
}

export async function getLocation() {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${window.location.origin}/api/getLocation`,
      success: res => resolve(res),
      error: err => reject(err),
    });
  });
}

export const getLocationReq = async () => {
  try {
    const res = await getLocation();
    if (res.ret === 0) {
      const { city = '' } = res.data;
      return city;
    }
  } catch (error) {
    return '';
  }
};

/**
 * 获取名站数据
 */
async function getListData() {
  if (!userData) {
    userData = await getUserData();
  }
  const city = await getLocationReq();
  // 剔除无效数据
  const list = userData?.list?.length ? userData.list : [];
  if (city.includes('北京')) {
    return list.filter((item) => {
      if (util.isSogou() && item.title === '百度') {
        item.url = 'https://www.baidu.com/?tn=87048150_dg&ch=2';
      }
      return item?.url;
    });
  }
  getFirstComeIn().then((firstComeIn) => {
    lcLog.info('getListData', `isFirstComeIn: ${firstComeIn}`, JSON.stringify(list));
  });
  return list;
}

export default {
  getListData,
};
