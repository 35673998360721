import UniversalReport from '@tencent/universal-report';
import { getGuid, getQimei36, getTGuid, isSogou } from '@/utils/util';

const getMtmp = () => window.location.href.split('?')[1] || '';
const bizParams = {
  page_id: 'quickstart',
  browser_type: isSogou() ? 'sogou' : 'qq',
  sduv: '1.0.0',
};
export let reporter: any;
if (!reporter) {
  const guid = getGuid() || '';
  reporter = new UniversalReport({
    beacon: '0WEB0KL4435PY3OM', // 灯塔 APPKEY 或者灯塔实例
    publicParams: {
      mtmp: getMtmp(),
      pg_isreturn: document.referrer === '' ? 0 : 1,
      uid: guid,
      guid,
      tguid: getTGuid() || '',
      q36: getQimei36(),
      ...bizParams,
    },
  });
  window.reporter = reporter;
}
