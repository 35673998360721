/* eslint-disable max-len */
import { defineComponent, ExtractPropTypes, PropType, ref, watch } from 'vue';
import { CalendarYearMonthDayType } from './types';
import { Solar } from 'lunar-typescript';
import { convertChineseMonthDay, formatChineseYear } from '../util';

export const CalendarDayProps = {
  currentYearMonth: {
    type: Object as PropType<CalendarYearMonthDayType>,
    default: () => ({}),
  },
};

export type CalendarDayPropsType = ExtractPropTypes<typeof CalendarDayProps>;

export default defineComponent({
  name: 'CalendarDay',

  props: CalendarDayProps,

  setup(props) {
    if (
      !props.currentYearMonth.year
      || !props.currentYearMonth.month
      || !props.currentYearMonth.day
    ) {
      return () => null;
    }
    const fitBad: any = ref({});
    // const fitBadMap = ref('');
    // const fitBadLock = ref(false);

    const holidayInfo: any = ref(null);
    const holidayInfoLock = ref(false);

    const solar = ref(Solar.fromYmd(
      props.currentYearMonth.year,
      props.currentYearMonth.month,
      props.currentYearMonth.day,
    ));
    const lunar = ref(solar.value.getLunar());

    const reCaculate = () => {
      solar.value = Solar.fromYmd(
        props.currentYearMonth.year,
        props.currentYearMonth.month,
        props.currentYearMonth.day,
      );
      lunar.value = solar.value.getLunar();
    };

    // const getYearMonthDayCn = () => `${solar.value.getYear()}%C4%EA${
    //   solar.value.getMonth() < 10
    //     ? `0${solar.value.getMonth()}`
    //     : solar.value.getMonth()
    // }%D4%C2${
    //   solar.value.getDay() < 10
    //     ? `0${solar.value.getDay()}`
    //     : solar.value.getDay()
    // }%C8%D5`;

    // https://www.sogou.com/websearch/features/baibao/calendar_ajax.jsp?query=2024%C4%EA05%D4%C223%C8%D5
    const getDateFitBad = () => {
      const yis = lunar.value.getDayYi();
      const jis = lunar.value.getDayJi();
      fitBad.value = {
        fit: yis.length === 1 && yis[0] === '无' ? '' : yis.join(' '),
        bad: jis.length === 1 && jis[0] === '无' ? '' : jis.join(' '),
      };
      // console.log('🚀 ~ getDateFitBad ~ getYearMonthDayCn():', yearMonthDayCn);
      // if (
      //   !fitBadLock.value
      //   && (!fitBadMap.value || fitBadMap.value !== yearMonthDayCn)
      // ) {
      //   fitBadLock.value = true;
      //   $.ajax({
      //     url: `https://feeds.qq.com/websearch/features/baibao/calendar_ajax.jsp?query=${yearMonthDayCn}`,
      //     success: (data) => {
      //       if (data) {
      //         try {
      //           console.log('🚀 ~ getDateFitBad ~ data:', data);
      //           fitBad.value = JSON.parse(data);
      //           fitBadMap.value = yearMonthDayCn;
      //           console.log('🚀 ~ getDateFitBad ~ fitBad.value:', fitBad.value);
      //           fitBadLock.value = false;
      //         } catch (error) {
      //           console.log('🚀 ~ getDateFitBad ~ error:', error);
      //           fitBadLock.value = false;
      //         }
      //       }
      //     },
      //   });
      // }
    };

    // https://m.sogou.com/tworeq/?dataPlatformSource=qahub&parity=073526e6-d091-11ee-bbf7-a66955343366&graySubGroup=0&reqClassids=21343201&grayGroup=1469&queryString=%25E6%2597%25A5%25E5%258E%2586&queryFrom=wap&vrForQc=false&grayNewCache=final&oriQuery=%25E6%2597%25A5%25E5%258E%2586&retType=xml&grayId=1&subReq=on&grayVridList=70235900&userArea=&ie=utf8&grayIsUseCache=1%5D&dump=1&forceQuery=1&format=json
    const getHoliday = () => {
      if (holidayInfoLock.value) {
        return;
      }
      holidayInfoLock.value = true;
      $.ajax({
        url: 'https://feeds.qq.com/tworeq/?dataPlatformSource=qahub&parity=073526e6-d091-11ee-bbf7-a66955343366&graySubGroup=0&reqClassids=21343201&grayGroup=1469&queryString=%25E6%2597%25A5%25E5%258E%2586&queryFrom=wap&vrForQc=false&grayNewCache=final&oriQuery=%25E6%2597%25A5%25E5%258E%2586&retType=xml&grayId=1&subReq=on&grayVridList=70235900&userArea=&ie=utf8&grayIsUseCache=1%5D&dump=1&forceQuery=1&format=json',
        success: (data) => {
          console.log('🚀 ~ getDateFitBad ~ data:', data);
          holidayInfoLock.value = false;
        },
      });
    };

    watch(
      () => props.currentYearMonth,
      (val) => {
        reCaculate();
        getDateFitBad();
        // getHoliday();
      },
      { deep: true, immediate: true },
    );

    const renderContainer = () => {
      const yearShengXiao = lunar.value.getYearShengXiao();
      const jieQi = lunar.value.getJieQi();
      const solarYear = solar.value.getYear();
      const solarMonth = solar.value.getMonth();
      const solarDay = solar.value.getDay();
      const solarFestival = solar.value.getFestivals();
      const lularFestival = lunar.value.getFestivals();
      const festival = [].concat(solarFestival, lularFestival).join(',');
      const fitBadFits = fitBad.value?.fit?.substring(0, 11)?.split(' ');
      const fitBadBads = fitBad.value?.bad?.substring(0, 11)?.split(' ');
      const hadFits = !!fitBad.value?.fit?.replace(/\s*/g, '');
      const hadBads = !!fitBad.value?.bad?.replace(/\s*/g, '');
      return (
        <div class="calendar_day_detail_wrapper" ref="containerRef">
          <div class="calendar_day_detail">
            <div class="calendar_day_detail_content">
              <div class="calendar_day_detail_content_left">
                <span>{`${lunar.value.getMonthInChinese()}月${lunar.value.getDayInChinese()}`}</span>
                <span>{jieQi}</span>
              </div>
              <div class="calendar_day_detail_content_mid">
                <div class="calendar_day_detail_title">
                  <span>
                    {formatChineseYear(`${props.currentYearMonth.year}`)}
                  </span>
                  <span>{`${lunar.value.getYearInGanZhi()}(${yearShengXiao})年`}</span>
                </div>
                <div class="calendar_day_detail_mid_day_wrapper">
                  <span class="calendar_day_detail_content_mid_day">
                    {props.currentYearMonth.day}
                  </span>
                  <span class="calendar_day_detail_content_mid_date">{`${solarYear}.${
                    solar.value.getMonth() < 10 ? `0${solarMonth}` : solarMonth
                  }.${
                    solar.value.getDay() < 10 ? `0${solarDay}` : solarDay
                  }`}</span>
                </div>
              </div>
              <div class="calendar_day_detail_content_right">
                <span>
                  {convertChineseMonthDay(
                    `${props.currentYearMonth.month}`,
                    `${props.currentYearMonth.day}`,
                  )}
                </span>
                <span>{`周${solar.value.getWeekInChinese()}`}</span>
              </div>
            </div>
            {
              (hadFits || hadBads) ? <div class="calendar_day_detail_fitbad">
                {hadFits ? (
                  <div class="calendar_day_fit">
                    <div class="calendar_day_fit_title">
                      <span>宜</span>
                    </div>
                    <div class="calendar_day_fit_wrapper">
                      {fitBadFits?.map((f: any, index: string | number | symbol | undefined) => (f ? <span key={index}>{f}</span> : null))}
                    </div>
                  </div>
                ) : null}
                {hadBads ? (
                  <div class="calendar_day_bad">
                    <div class="calendar_day_bad_title">
                      <span>忌</span>
                    </div>
                    <div class="calendar_day_bad_wrapper">
                      {fitBadBads?.map((f: any, index: string | number | symbol | undefined) => (f ? <span key={index}>{f}</span> : null))}
                    </div>
                  </div>
                ) : null}
              </div> : null
            }
          </div>
          <div class="calendar_day_detail_info">
            <div class="calendar_day_detail_info_line">
              <span class="calendar_day_detail_info_line_label">生肖</span>
              <span>{yearShengXiao}</span>
            </div>
            <div class="calendar_day_detail_info_line">
              <span class="calendar_day_detail_info_line_label">星座</span>
              <span>{solar.value.getXingZuo()}</span>
            </div>
            {festival ? (
              <div class="calendar_day_detail_info_line">
                <span class="calendar_day_detail_info_line_label">节日</span>
                <span>{festival}</span>
              </div>
            ) : null}
            {jieQi ? (
              <div class="calendar_day_detail_info_line">
                <span class="calendar_day_detail_info_line_label">节气</span>
                <span>{jieQi}</span>
              </div>
            ) : null}
          </div>
        </div>
      );
    };

    return () => renderContainer();
  },
});
