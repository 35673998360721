<script setup lang="ts">
import { ref } from 'vue';
import ModalContainer from '@/components/modal/modal/index.vue';
import Calculator from './components/calculator/index.vue';
import HouseLoan from './components/house-loan/index.vue';
import { WIDGET_MODE } from '@/types';

const props = defineProps({
  showName: {
    type: Boolean,
    default: true,
  },
  mode: {
    type: String,
    default: WIDGET_MODE.common,
    required: false,
  },
});
const showModal = ref(false);
const tabs = ref([
  {
    label: '计算器',
    value: Calculator,
    icon: 'https://m4.publicimg.browser.qq.com/publicimg/pcqb/newtab/components/calc.png',
  },
  {
    label: '住房贷款',
    value: HouseLoan,
    icon: 'https://m4.publicimg.browser.qq.com/publicimg/pcqb/newtab/components/loan.png',
  },
  // {
  //   label: '个税计算',
  //   value: 'personal_tax',
  //   icon: 'https://m4.publicimg.browser.qq.com/publicimg/pcqb/newtab/components/salary.png',
  // },
  // {
  //   label: '汇率换算',
  //   value: 'exchange_rate',
  //   icon: 'https://m4.publicimg.browser.qq.com/publicimg/pcqb/newtab/components/rate.png',
  // },
]);
const activeTab = ref(Calculator);

const onTabClick = (tab) => {
  activeTab.value = tab.value;
};

const close = () => {
  showModal.value = false;
};

const handleCalculatorClick = () => {
  if (props.mode === WIDGET_MODE.preview) return;
  showModal.value = true;
};
</script>
<template>
  <div
    class="calculator-widget h-full w-full"
  >
    <div
      class="h-full w-full contents"
      @click="handleCalculatorClick"
    >
      <div class="calculator-content" />
    </div>
    <p
      v-if="props.showName"
      class="item-title widget-item-name"
    >
      计算器
    </p>
    <ModalContainer
      v-if="showModal"
      class="calculator-modal-container"
      @close="close"
    >
      <div class="calculator-modal-content h-full">
        <div class="modal-left">
          <h2 class="modal-title">
            计算器
          </h2>
          <div class="tabs">
            <div
              v-for="tab in tabs"
              :key="tab.value"
              :class="{
                tab: true,
                'active': tab.value === activeTab,
              }"
              @click="onTabClick(tab)"
            >
              <i
                class="tab-icon"
                :style="{
                  backgroundImage: `url(${tab.icon})`
                }"
              />
              <span class="tab-label">{{ tab.label }}</span>
            </div>
          </div>
        </div>
        <div class="modal-right">
          <component :is="activeTab" />
        </div>
      </div>
    </ModalContainer>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.calculator-modal-container {
  &.modal-container {
    .modal-body {
      padding: 0;
      .modal-content {
        height: 100%;
        max-height: none !important;
        overflow-y: visible;
      }
    }
  }
}
</style>
