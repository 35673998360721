import RightContextMenu from '@/components/context-menu/index.vue';
import { createApp } from 'vue';

let instance: any = null;

export default {
  /**
   *
   * @param options
   * @returns
   * .then参数为 left 或right
   */
  show(options: any = {}) {
    return new Promise(() => {
      if (instance) {
        instance.destroy();
      }
      const app = createApp(RightContextMenu, options);
      const container = document.createDocumentFragment();
      instance = app.mount(container as unknown as Element);
      document.body.appendChild(container);
      instance.visible = true;
      instance.destroy = function () {
        app.unmount();
        instance = null;
      };
    });
  },
  hide() {
    if (instance) {
      instance.visible = false;
      instance.destroy();
    }
  },
};
