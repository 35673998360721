<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue';
import ToastBox from './toast-box.vue';
import { ToastMessage } from './type';

const toasts = ref<ToastMessage[]>([]);

const handleAddToast = (event: CustomEvent<ToastMessage>) => {
  toasts.value = [event.detail];
};

const handleClose = (id: string) => {
  toasts.value = toasts.value.filter(toast => toast.id !== id);
};

onMounted(() => {
  window.addEventListener('addToast', handleAddToast as EventListener);
});

onBeforeUnmount(() => {
  window.removeEventListener('addToast', handleAddToast as EventListener);
});

</script>
<template>
  <div class="container">
    <ToastBox
      v-for="toast in toasts"
      :id="toast.id"
      :key="toast.id"
      :message="toast.message"
      :type="toast.type"
      :duration="toast.duration"
      @close="handleClose"
    />
  </div>
</template>

<style lang="less" scoped>
.container {
  position: fixed;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
